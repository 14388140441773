import WidgetFavoriteEntity from '@/components/lap/synapse-widgets/models/WidgetFavoriteEntity';

export const state = () => ({
  favoritePathologies: [],
  hasLoadedFavoritePathologies: false,
  isLoadingFavoritePathologies: false,
});

export const getters = {
  getFavoritePathologies: state => state.favoritePathologies || [],
  getHasLoadedFavoritePathologies: state => state.hasLoadedFavoritePathologies,
  isLoadingFavoritePathologies: state => state.isLoadingFavoritePathologies,
};

export const mutations = {
  SET_FAVORITE_PATHOLOGY(state, pathology) {
    state.favoritePathologies.push(pathology);
  },
  SET_HAS_LOADED_FAVORITE_PATHOLOGIES (state, hasLoadedFavoritePathologies) {
    state.hasLoadedFavoritePathologies = hasLoadedFavoritePathologies;
  },
  SET_IS_LOADING_FAVORITE_PATHOLOGIES (state, isLoadingFavoritePathologies) {
    state.isLoadingFavoritePathologies = isLoadingFavoritePathologies;
  },
  DELETE_FAVORITE_PATHOLOGY (state, pathology) {
    const index = state.favoritePathologies.indexOf(pathology);
    if (index > -1) {
      state.favoritePathologies.splice(index, 1);
    }
  },
};

export const actions = {
  async fetchFavoritePathologies({ state, commit }) {
    commit('SET_IS_LOADING_FAVORITE_PATHOLOGIES', true);
    try {
      const favoritePathologies = await WidgetFavoriteEntity.fetchAll('PathologyAutocompleteWidgetV2');
      favoritePathologies.forEach(fav => commit('SET_FAVORITE_PATHOLOGY', fav));
    } finally {
      commit('SET_IS_LOADING_FAVORITE_PATHOLOGIES', false);

      if (!state.hasLoadedFavoritePathologies) {
        commit('SET_HAS_LOADED_FAVORITE_PATHOLOGIES', true);
      }
    }
  },
  async insertFavoritePathology ({ commit }, pathology) {
    const newFavoritePathology = await WidgetFavoriteEntity.insert('PathologyAutocompleteWidgetV2', pathology);
    commit('SET_FAVORITE_PATHOLOGY', newFavoritePathology);
  },
  async deleteFavoritePathology ({ commit }, pathology) {
    await WidgetFavoriteEntity.delete(pathology);
    commit('DELETE_FAVORITE_PATHOLOGY', pathology);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};