<template>
  <article
    v-test="'consultation-file-list-item'"
    :class="{ 'consultation-files-list-item--has-icon': !! icon }"
    class="consultation-files-list-item"
    @click="handleClickItem"
  >
    <slot name="prepend-title" />
    <header class="consultation-files-list-item__headline">
      <h1 class="consultation-files-list-item__headline__title">
        <app-icon
          v-if="icon"
          v-test="'item-icon'"
          :icon="icon"
          color="secondary"
        />
        <span
          v-test="'consultation-file-list-item-title'"
          class="consultation-files-list-item__headline__title__text"
          @click="openPdf"
        >
          <slot name="title" />
        </span>
        <document-dmp-status-badge
          v-if="showDmpStatus"
          v-test="'dmp-status-badge'"
          :dmp-sync="dmpStatusSync"
        />
      </h1>
      <app-actions-menu
        v-if="! hideActions"
        v-test="'actions'"
        :actions="completeActions"
        :max-actions-visible="1"
      />
    </header>
    <main
      v-if="$scopedSlots.description"
      class="consultation-files-list-item__content"
    >
      <slot name="description" />
    </main>
  </article>
</template>

<script>
import { cloneDeep } from 'lodash';

import ActionMenuItem from '@/components/ui/actionsMenu/classes/ActionMenuItem';
import PrescriptionTemplate from '@/modules/patient/components/consultation/prescription/templates/models/PrescriptionTemplate';
import ConsultationBaseDocument from '@/modules/patient/models/ConsultationBaseDocuments';
import Prescription from '@/modules/patient/models/Prescription';
import PdfFile from '@/utils/classes/files/PdfFile';
import { isEqual } from '@/utils/functions/dates';

import AppActionsMenu from '@/components/ui/actionsMenu/AppActionsMenu.vue';
import DocumentDmpStatusBadge from '@/modules/patient/dmp/DocumentDmpStatusBadge.vue';



/**
 * Ce composant permet d'afficher un
 * fichier de consultation
 */
export default {
  name: 'ConsultationFileListItem',
  components: {
    AppActionsMenu,
    DocumentDmpStatusBadge,
  },
  props: {
    /**
     * Le document à afficher
     */
    file: {
      type: Object,
      required: true,
      validator: file => (file instanceof ConsultationBaseDocument || file instanceof Prescription),
    },
    /**
     * L'icône affiché pour le document
     */
    icon: {
      type: String,
      default: null,
    },
    /**
     * Densifie l'affichage de l'élément
     */
    dense: {
      type: Boolean,
      default: false,
    },
    actions: {
      type: Array,
      default: null,
    },
    hideActions: {
      type: Boolean,
      default: false,
    },
    hideBaseActions: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    completeActions () {
      const baseActions = this.hideBaseActions ? [] : [new ActionMenuItem('download', 'Télécharger', this.download)];
      if (! this.actions) {
        return baseActions;
      }
      if (this.actions[0] instanceof Array) {
        const newActions = cloneDeep(this.actions);
        newActions[0].unshift(...baseActions);
        return newActions;
      }
      return [...baseActions, ...this.actions];
    },
    showDmpStatus () {
      return ! (this.file instanceof PrescriptionTemplate);
    },
    dmpStatusSync () {
      const document = this.file.document ? this.file.document : this.file;
      const isSynchronised = !! document.dmpUniqueId;
      if (isSynchronised) {
        return isEqual(document.updatedAt, document.dmpSyncDate);
      }
      return false;
    },
  },
  methods: {
    handleClickItem () {
      this.$emit('click');
    },
    openPdf () {
      new PdfFile(this.file.contentUrl).open();
    },
    download () {
      new PdfFile(this.file.contentUrl).download({ fileName: this.file.name });
    },
  },
};
</script>

<style lang="scss" scoped>
.consultation-files-list-item {
  position: relative;

  &--has-icon &__headline__title {
    grid-template-columns: 20px 1fr 40px;
  }

  &__headline {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 20px;
    margin-bottom: map-get($spacers, 1);

    &__title {
      display: grid;
      grid-template-columns: 1fr 30px;
      align-items: center;
      gap: map-get($spacers, 3);
      color: var(--v-content-base);
      font-size: 13px;
      font-weight: 400;
      overflow: hidden;

      &__text {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        cursor: pointer;
        user-select: none;

        &:hover {
          color: var(--v-primary-base);
          text-decoration: underline;
        }
      }
    }
  }
}
</style>