import PatientBillDataset from './datasets/PatientBillDataset';
import { CONTACT_POINT_LABELS, MEDIA_TYPES, GENDERS } from '@/constants';
import Person from '@/models/Person';
import Contact from '@/models/user/Contact';
import PatientInsIdentity from '@/modules/patient/models/PatientInsIdentity';
import PatientKeyword from '@/modules/patient/models/PatientKeyword';
import { getApiSerializedPatient } from '@/modules/patient/utils/getApiSerializedPatient';
import { getBirthPlaceFullLabel } from '@/modules/patient/utils/getBirthPlaceFullLabel';
import NovaTools from '@/nova-tools/NovaTools';
import { deleteFromAPI, getFromAPI, postToAPI, putToAPI } from '@/services/api';
import { getAge, intervalToDuration } from '@/utils/functions/dates';
import { getCivilState } from '@/utils/functions/persons';
import { genderize } from '@/utils/functions/words';

/**
 * @typedef {Object} PatientType
 * @property {String} publicId
 * @property {String} profession
 * @property {String} birthName
 * @property {String} gender
 * @property {String} firstName
 * @property {String} usedFirstName
 * @property {String} familySituation
 * @property {Object} referringPhysician
 * @property {String} referringPhysicianHealthProfessional
 * @property {String} nir
 * @property {String} note
 * @property {PatientBillDatasetType} billDataset
 * @property {Object} medicalDataset
 * @property {String} femaleMedicalDataset
 * @property {Object} insIdentity
 * @property {String} birthPlaceCode
 * @property {String} birthPlaceLabel
 * @property {Boolean} archived
 * @property {Boolean} isDeceased
 * @property {String} deathDate
 * @property {Boolean} nirAllowUnchecked
 * @property {String} transactionId
 */
class Patient extends Person {
  /**
   * @param {{'@id': string}} options
   */
  constructor (options = {}) {
    super(options);
    this.publicId = options.publicId || null;
    this.profession = options.profession || null;
    this.birthName = options.birthName || null;
    this.gender = options.gender || null;
    this.usedFirstName = options.usedFirstName || null;
    this.usedIdentity = options.usedIdentity || null;
    this.familySituation = options.familySituation || null;
    this.referringPhysician = options.referringPhysician ? new Contact(options.referringPhysician) : null;
    this.referringPhysicianHealthProfessional = options.referringPhysicianHealthProfessional || null;
    this.nir = options.nir || null;
    this.note = options.note || null;
    this.billDataset = options.billDataset ? new PatientBillDataset(options.billDataset) : null;
    this.medicalDataset = options.medicalDataset || null;
    this.femaleMedicalDataset = options.femaleMedicalDataset || null;
    this.insIdentity = options.insIdentity ? new PatientInsIdentity(options.insIdentity) : new PatientInsIdentity();
    this.birthPlaceCode = options.birthPlaceCode || null;
    this.birthPlaceLabel = options.birthPlaceLabel || null;
    this.mssDocumentConsent = (options.mssDocumentConsent !== undefined) ? options.mssDocumentConsent : true;
    this.archived = options.archived || false;
    this.keywords = options.keywords
      ? options.keywords.map(keyword => new PatientKeyword(keyword))
      : [];
    this.isDeceased = options.isDeceased || false;
    this.deathDate = options.deathDate || null;
    this.medicalNote = options.medicalNote || null;
    this.nirAllowUnchecked = options.nirAllowUnchecked || false;
    this.isDuplicated = options.isDuplicated || false;
    this.transactionId = options.transactionId || null;
  }

  /**
   * Insère un patient vers l'API
   * @param {Patient} patient
   * @returns {Patient}
   */
  static async insert (patient) {
    const { data } = await postToAPI('/api/patients', getApiSerializedPatient(patient));
    return new Patient(data);
  }

  /**
   * Mettre à jour un patient à partir de l'API
   * @param {Patient} patient
   * @returns {Patient}
   */
  static async update (patient) {
    const { data } = await putToAPI(patient['@id'], { data: getApiSerializedPatient(patient) });
    return new Patient(data);
  }

  /**
   * Permet de savoir de part une interrogation de l'API
   * Si un patient peut être supprimé.
   * @param {String} patientUuid
   * @returns {Boolean}
   */
  static async isDeletable (patientUuid) {
    const { data } = await getFromAPI(`/api/patients/${patientUuid}/is_deletable`);
    return data.deletable;
  }

  /**
   * Supprime un patient à partir de l'API
   * @param {String} patientUuid
   */
  static async delete (patientUuid) {
    await deleteFromAPI(`/api/patients/${patientUuid}`);
  }

  /**
   * Retourne les informations du lieu de naissance du patient en fonction de son code et de son label
   * @example Marseille (13055)
   * @returns {String}
   */
  getBirthPlaceLabel () {
    return this.birthPlaceCode !== null
      ? `${getBirthPlaceFullLabel(this.birthPlaceCode, this.birthPlaceLabel)} (${this.birthPlaceCode})` : null;
  }

  /**
   * Retourne l'intitulé du sexe/genre en fonction de l'attribut gender
   * @returns {String}
   */
  getGenderLabel () {
    return this.gender ? GENDERS[this.gender?.toUpperCase()]?.label : null;
  }

  /**
   * Renvoi la liste des 5 traits stricts du patient
   * @returns {Array} les valeurs des 5 traits stricts du patient
   */
  getStrictTraits () {
    return [
      this.firstNames,
      this.firstName,
      this.birthName,
      this.birthPlaceCode,
      this.gender === 'unknown' ? null : this.getGenderLabel(),
    ].filter(trait => trait);
  }

  haveAllStrictTraits () {
    return this.getStrictTraits().length === 5;
  }

  /**
   * Retourne l'INS du patient suivi de son type
   * @returns {String}
   */
  getInsLabel () {
    if (this.insIdentity.ins) {
      return `${this.insIdentity.ins} (${this.insIdentity.isNia() ? 'NIA' : 'NIR'})`;
    }
    return null;
  }

  /**
   * Retourne la date de naissance sous la forme Jours/Mois/Année
   * @param {Object} options
   * @param {Boolean} options.bornOn Indique si la date de naissance doit être préfixée par "Né(e) le"
   * @return {String} Les date de naissance formatée du patient
   */
  getFormattedBirthDate (options = {}) {
    const birthDate = NovaTools.dates.format(this.birthDate, 'dd/MM/yyyy');
    if (options.bornOn) {
      const bornOn = `${genderize('Né', this.gender)} le`;
      return `${bornOn} ${birthDate}`;
    }
    return birthDate;
  }

  /**
   * Retourne la date de décès sous la forme Jours/Mois/Année
   * @param {Object} options
   * @param {Boolean} options.deadOn Indique si la date de décès doit être préfixée par "Décédé(e) le"
   * @return {String} La date de décès formatée du patient
   */
  getFormattedDeathDate (options = {}) {
    const deathDate = NovaTools.dates.format(this.deathDate, 'dd/MM/yyyy');
    if (options.deadOn) {
      const deadOn = `${genderize('Décédé', this.gender)} le`;
      return `${deadOn} ${deathDate}`;
    }
    return deathDate;
  }

  /**
   * Retourne l'age du patient formaté différemment selon son age
   * @return { String } L'age du patient
   */
  getAge (options = {}) {
    return getAge(this.birthDate, {
      ...options,
      deathDate: this.deathDate,
    });
  }

  /**
   * Retourne l'age du patient arrondi en année
   * @return { Number } L'age du patient
   */
  getYears () {
    const birthDate = this.birthDate instanceof Date ? this.birthDate : new Date(this.birthDate);
    let stopDate = this.deathDate ?? new Date();
    stopDate = stopDate instanceof Date ? stopDate : new Date(stopDate);
    const { years } = intervalToDuration({
      start: birthDate,
      end: stopDate,
    });
    return years;
  }

  /**
   * Retourne l'état civil du patient en tenant compte de son nom utilisé / nom de naissance
   * @returns {String}
   */
  getCivilState () {
    if (this.usedIdentity) {
      return this.usedIdentity;
    }
    return `${this.usedFirstName || this.firstName} ${(this.familyName || this.birthName)?.toUpperCase()}`;
  }

  /**
   * Retourne l'adresse MSS citoyenne du patient à partir des informations sur son identité
   * @returns {String}
   */
  getPublicMssEmail () {
    if (this.insIdentity.ins) {
      return `${this.insIdentity.ins}@patient.mssante.fr`;
    }
    return null;
  }

  /**
   * Permet de savoir si le patient est de sexe inconnu
   * @returns {Boolean}
   */
  isGenderUnknown () {
    return this.gender === GENDERS.UNKNOWN.value || ! this.gender;
  }

  hasIns () {
    return this.insIdentity.hasIns();
  }

  /**
   * @deprecated
   * @see getCivilState
   */
  get civilState () {
    return getCivilState(this);
  }

  /**
   * @deprecated
   */
  get age () {
    return getAge(this.birthDate);
  }

  /**
   * @deprecated
   */
  get availablePhones () {
    return this.contactPoints.filter(el => (el.media === MEDIA_TYPES.TELEPHONE.value) && el.value);
  }

  /**
   * @deprecated
   */
  get mobile () {
    return this.availablePhones.find(el => el.label === CONTACT_POINT_LABELS.MOBILE.value);
  }

  /**
   * @deprecated
   */
  get otherPhone () {
    return this.availablePhones.find(el => el.label === CONTACT_POINT_LABELS.OTHER.value);
  }

  /**
   * @deprecated
   */
  get email () {
    return this.contactPoints.find(el => ((el.media === MEDIA_TYPES.EMAIL.value) && ! el.label) && el.value);
  }
}

export default Patient;