import Vue from 'vue';

import { state as drugAllergiesState, getters as drugAllergiesGetters, mutations as drugAllergiesMutations, actions as drugAllergiesActions } from './drugAllergies';
import { state as nonDrugAllergiesState, getters as nonDrugAllergiesGetters, mutations as nonDrugAllergiesMutations, actions as nonDrugAllergiesActions } from './nonDrugAllergies';
import { state as pathologiesState, getters as pathologiesGetters, mutations as pathologiesMutations, actions as pathologiesActions } from './pathologies';
import { state as patientAntecedentsState, getters as patientAntecedentsGetters, mutations as patientAntecedentsMutations, actions as patientAntecedentsActions } from './patientAntecedents';
import { getFromAPI } from '@/services/api';

export const state = () => ({
  ...nonDrugAllergiesState(),
  ...drugAllergiesState(),
  ...pathologiesState(),
  ...patientAntecedentsState(),
});

export const getters = {
  ...nonDrugAllergiesGetters,
  ...drugAllergiesGetters,
  ...pathologiesGetters,
  ...patientAntecedentsGetters,
};

export const mutations = {
  CLEAR_RISK_FACTOR (state, { riskFactorName, patientUuid }) {
    Vue.set(state[riskFactorName], patientUuid, {});
  },
  ...nonDrugAllergiesMutations,
  ...drugAllergiesMutations,
  ...pathologiesMutations,
  ...patientAntecedentsMutations,
};

export const actions = {
  async fetchPatientRiskFactor ({ commit }, { route, patientUuid, riskFactorName }) {
    try {
      const { data } = await getFromAPI(route);
      commit('CLEAR_RISK_FACTOR', {
        riskFactorName,
        patientUuid,
      });
      return data['hydra:member'];
    } catch (e) {
      throw new Error(e);
    }
  },
  ...nonDrugAllergiesActions,
  ...drugAllergiesActions,
  ...pathologiesActions,
  ...patientAntecedentsActions,
};

export default {
  state,
  getters,
  mutations,
  actions,
};