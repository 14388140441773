<template>
  <app-form :key="mergeAdministrativePatientFolderFormKey" :submit-method="submit">
    <validation-provider
      :rules="{ checkboxIsNotEmpty: { checkboxValues: getCheckboxValues } }"
    >
      <v-row>
        <v-col offset="3" cols="4">
          <duplicate-patient-identity :patient="value.patient" :used-identity-tag="false" />
        </v-col>
        <v-col cols="4">
          <duplicate-patient-identity
            :patient="value.selectedTargetPatient"
            avatar-color
            :used-identity-tag="false"
          />
        </v-col>
      </v-row>
      <div v-if="noChoiceToMake" v-test="'merge-administrative-patient-folder-no-field'" class="merge-administrative-folder__info">
        Aucune différence observée dans les données administratives
      </div>
      <div v-else v-test="'merge-administrative-patient-folder-fields'" class="merge-administrative-folder__info">
        Seuls les champs avec des données différentes sont affichés
      </div>
      <div>
        <template v-for="field in fields">
          <component
            :is="field.component"
            v-if="adaptiveDisplayField(field)"
            :key="field.key"
            v-model="field.model[field.props['field-name']]"
            v-bind="field.props"
          >
            {{ field.label }}
          </component>
        </template>
      </div>
    </validation-provider>
  </app-form>
</template>

<script>
import { clone } from 'lodash';
import { extend, ValidationProvider } from 'vee-validate';

import { FAMILY_SITUATIONS, CONTACT_POINT_LABELS, MEDIA_TYPES , CIVILITIES } from '@/constants';

import AppForm from '@/components/ui/form/AppForm.vue';
import DuplicatePatientIdentity
  from '@/modules/patient/components/sidebar/duplicates/components/DuplicatePatientIdentity.vue';
import MergeDuplicatesCheckbox
  from '@/modules/patient/components/sidebar/duplicates/components/MergeDuplicatesCheckbox.vue';
import MergeDuplicatesRadio
  from '@/modules/patient/components/sidebar/duplicates/components/MergeDuplicatesRadio.vue';

export default {
  name: 'MergeAdministrativePatientFolderForm',
  components: {
    DuplicatePatientIdentity,
    AppForm,
    MergeDuplicatesCheckbox,
    MergeDuplicatesRadio,
    ValidationProvider,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      mergedPatient: null,
      mergeAdministrativePatientFolderFormKey: 0,
      noChoiceToMake: true,
      hasOriginalNote: false,
      hasOriginalProfession: false,
      displayedFields: [],
    };
  },
  computed: {
    fields () {
      return [
        {
          key: 'civility',
          label: 'Civilité',
          component: 'merge-duplicates-radio',
          model: this.mergedPatient?.patient,
          props: {
            'patient-field': this.value.patient.civility,
            'patient-label': this.getCivilityLabel(this.value.patient.civility),
            'selected-target-patient-field': this.value.selectedTargetPatient.civility,
            'selected-target-patient-label': this.getCivilityLabel(this.value.selectedTargetPatient.civility),
            'field-name': 'civility',
          },
        },
        {
          key: 'familyName',
          label: 'Nom utilisé',
          component: 'merge-duplicates-radio',
          model: this.mergedPatient?.patient,
          props: {
            'patient-field': this.value.patient.familyName,
            'selected-target-patient-field': this.value.selectedTargetPatient.familyName,
            'field-name': 'familyName',
          },
        },
        {
          key: 'usedFirstName',
          label: 'Prénom utilisé',
          component: 'merge-duplicates-radio',
          model: this.mergedPatient?.patient,
          props: {
            'patient-field': this.value.patient.usedFirstName,
            'selected-target-patient-field': this.value.selectedTargetPatient.usedFirstName,
            'field-name': 'usedFirstName',
          },
        },
        {
          key: 'nir',
          label: 'Numéro de sécurité sociale',
          component: 'merge-duplicates-radio',
          model: this.mergedPatient?.patient,
          props: {
            'patient-field': this.value.patient.nir,
            'selected-target-patient-field': this.value.selectedTargetPatient.nir,
            'field-name': 'nir',
          },
        },
        {
          key: 'mobile',
          label: 'Téléphone mobile',
          component: 'merge-duplicates-radio',
          model: this.mergedPatient?.contactPoints,
          props: {
            'patient-field': this.mobile(this.value.patient),
            'selected-target-patient-field': this.mobile(this.value.selectedTargetPatient),
            'field-name': 'mobile',
          },
        },
        {
          key: 'personalPhone',
          label: 'Téléphone personnel',
          component: 'merge-duplicates-radio',
          model: this.mergedPatient?.contactPoints,
          props: {
            'patient-field': this.personalPhone(this.value.patient),
            'selected-target-patient-field': this.personalPhone(this.value.selectedTargetPatient),
            'field-name': 'personal',
          },
        },
        {
          key: 'businessPhone',
          label: 'Téléphone professionnel',
          component: 'merge-duplicates-radio',
          model: this.mergedPatient?.contactPoints,
          props: {
            'patient-field': this.businessPhone(this.value.patient),
            'selected-target-patient-field': this.businessPhone(this.value.selectedTargetPatient),
            'field-name': 'business',
          },
        },
        {
          key: 'otherPhone',
          label: 'Autre téléphone',
          component: 'merge-duplicates-radio',
          model: this.mergedPatient?.contactPoints,
          props: {
            'patient-field': this.otherPhone(this.value.patient),
            'selected-target-patient-field': this.otherPhone(this.value.selectedTargetPatient),
            'field-name': 'other',
          },
        },
        {
          key: 'email',
          label: 'Email',
          component: 'merge-duplicates-radio',
          model: this.mergedPatient?.contactPoints,
          props: {
            'patient-field': this.email(this.value.patient),
            'selected-target-patient-field': this.email(this.value.selectedTargetPatient),
            'field-name': 'email',
          },
        },
        {
          key: 'address',
          label: 'Adresse',
          component: 'merge-duplicates-radio',
          model: this.mergedPatient,
          props: {
            'patient-field': 'patient',
            'patient-label': this.getAddressLabel(this.value.patient.address),
            'selected-target-patient-field': 'selectedPatient',
            'selected-target-patient-label': this.getAddressLabel(this.value.selectedTargetPatient.address),
            'field-name': 'address',
          },
        },
        {
          key: 'familySituation',
          label: 'Situation familiale',
          component: 'merge-duplicates-radio',
          model: this.mergedPatient?.patient,
          props: {
            'patient-field': this.value.patient.familySituation,
            'patient-label': this.getFamilySituationLabel(this.value.patient),
            'selected-target-patient-field': this.value.selectedTargetPatient.familySituation,
            'selected-target-patient-label': this.getFamilySituationLabel(this.value.selectedTargetPatient),
            'field-name': 'familySituation',
          },
        },
        {
          key: 'profession',
          label: 'Profession(s)',
          component: 'merge-duplicates-checkbox',
          model: this.mergedPatient?.patient,
          props: {
            'patient-field': this.value.patient.profession,
            'selected-target-patient-field': this.value.selectedTargetPatient.profession,
            'field-name': 'profession',
          },
        },
        {
          key: 'note',
          label: 'Notes',
          component: 'merge-duplicates-checkbox',
          model: this.mergedPatient?.patient,
          props: {
            'patient-field': this.value.patient.note,
            'selected-target-patient-field': this.value.selectedTargetPatient.note,
            'field-name': 'note',
          },
        },
        {
          key: 'deathDate',
          label: 'Date de décès',
          component: 'merge-duplicates-radio',
          model: this.mergedPatient?.patient,
          props: {
            'patient-field': this.value.patient.deathDate,
            'patient-label': this.getDeathDateLabel(this.value.patient),
            'selected-target-patient-field': this.value.selectedTargetPatient.deathDate,
            'selected-target-patient-label': this.getDeathDateLabel(this.value.selectedTargetPatient),
            'field-name': 'deathDate',
          },
        },
      ];
    },
    getCheckboxValues () {
      if (this.hasOriginalNote && this.hasOriginalProfession) {
        return Boolean(this.mergedPatient?.patient?.note) && Boolean(this.mergedPatient?.patient?.profession);
      }
      if (this.hasOriginalNote) {
        return Boolean(this.mergedPatient?.patient?.note);
      }
      if (this.hasOriginalProfession) {
        return Boolean(this.mergedPatient?.patient?.profession);
      }
      return true;
    },
  },
  watch: {
    'value.selectedTargetPatient': {
      immediate: true,
      deep: true,
      handler () {
        if (this.value.mergedPatient) {
          this.hasOriginalNote = Boolean(this.value.mergedPatient.note && this.value.selectedTargetPatient.note);
          this.hasOriginalProfession = Boolean(this.value.mergedPatient.profession && this.value.selectedTargetPatient.profession);
          this.mergedPatient = {
            patient: clone(this.value.mergedPatient),
            address: 'mergedPatient',
            contactPoints: {
              mobile: this.value.mergedPatient.contactPoints.find(cp => cp.label === CONTACT_POINT_LABELS.MOBILE.value)?.value,
              other: this.value.mergedPatient.contactPoints
                  .find(cp => (cp.media === MEDIA_TYPES.TELEPHONE.value) && (cp.label === CONTACT_POINT_LABELS.OTHER.value))?.value,
              personal: this.value.mergedPatient.contactPoints
                  .find(cp => (cp.media === MEDIA_TYPES.TELEPHONE.value) && (cp.label === CONTACT_POINT_LABELS.PERSONAL.value))?.value,
              business: this.value.mergedPatient.contactPoints
                  .find(cp => (cp.media === MEDIA_TYPES.TELEPHONE.value) && (cp.label === CONTACT_POINT_LABELS.BUSINESS.value))?.value,
              email: this.value.mergedPatient.contactPoints.find(cp => (cp.media === MEDIA_TYPES.EMAIL.value) && ! cp.label)?.value,
            },
          };
        }
      },
    },
    'value.resetForms': {
      immediate: true,
      deep: true,
      handler (newValue) {
        if (newValue) {
          this.mergeAdministrativePatientFolderFormKey += 1;
        }
      },
    },
  },
  methods: {
    submit () {
      let mergedPatient = this.mergedPatient.patient;
      mergedPatient = this.updateContactPoints(mergedPatient, this.mergedPatient.contactPoints);
      mergedPatient = this.updateAddress(mergedPatient);

      return {
        patient: mergedPatient,
        resetForms: this.getResetForms(),
        lastSelectedTargetPatientId: this.value.lastSelectedTargetPatientId,
        displayedFields: this.displayedFields,
      };
    },
    updateContactPoints (patient, contactPoints) {
      patient = this.updateContactPoint(patient, cp => cp.label === CONTACT_POINT_LABELS.MOBILE.value, contactPoints.mobile);
      patient = this.updateContactPoint(
        patient,
        cp => (cp.media === MEDIA_TYPES.TELEPHONE.value) && (cp.label === CONTACT_POINT_LABELS.OTHER.value),
        contactPoints.other
      );
      patient = this.updateContactPoint(
        patient,
        cp => (cp.media === MEDIA_TYPES.TELEPHONE.value) && (cp.label === CONTACT_POINT_LABELS.PERSONAL.value),
        contactPoints.personal
      );
      patient = this.updateContactPoint(
        patient,
        cp => (cp.media === MEDIA_TYPES.TELEPHONE.value) && (cp.label === CONTACT_POINT_LABELS.BUSINESS.value),
        contactPoints.business
      );
      patient = this.updateContactPoint(patient, cp => (cp.media === MEDIA_TYPES.EMAIL.value) && ! cp.label, contactPoints.email);

      return patient;
    },
    updateContactPoint (patient, condition, newValue) {
      const cp = patient.contactPoints.find(condition);
      if (cp) {
        patient.contactPoints.find(condition).value = newValue;
      }
      return patient;
    },
    updateAddress (mergedPatient) {
      if (this.mergedPatient.address === 'patient') {
        return this.setAddress(mergedPatient, this.value.patient.address);
      }
      if (this.mergedPatient.address === 'selectedPatient') {
        return this.setAddress(mergedPatient, this.value.selectedTargetPatient.address);
      }

      return mergedPatient;
    },
    setAddress (mergedPatient, address) {
      mergedPatient.address.line1 = address.line1;
      mergedPatient.address.line2 = address.line2;
      mergedPatient.address.postalCode = address.postalCode;
      mergedPatient.address.city = address.city;
      mergedPatient.address.country = address.country;

      return mergedPatient;
    },
    adaptiveDisplayField (field) {
      const specialFields = ['address'];
      return specialFields.includes(field.key) ?
        this.displayField(field.label, field.props['patient-label'], field.props['selected-target-patient-label']) :
        this.displayField(field.label, field.props['patient-field'], field.props['selected-target-patient-field']);
    },
    displayField (label, sourceField, targetField) {
      if (! sourceField || ! targetField) {
        return false;
      }

      const display = (sourceField !== targetField);
      if (! display) {
        return false;
      }

      if (! this.displayedFields.includes(label)) {
        this.displayedFields.push(label);
      }
      if (this.noChoiceToMake) {
        this.noChoiceToMake = false;
      }

      return true;
    },
    getAddressLabel (address) {
      if (! address || typeof address !== 'object') {
        return null;
      }

      return address.getAddressLabel();
    },
    getDeathDateLabel (patient) {
      return patient.deathDate ? patient.getFormattedDeathDate() : null;
    },
    getFamilySituationLabel (patient) {
      return FAMILY_SITUATIONS[patient.familySituation?.toUpperCase()]?.label;
    },
    getCivilityLabel (civility) {
      return CIVILITIES[civility?.toUpperCase()]?.shortLabel;
    },
    mobile (patient) {
      return patient.contactPoints.find(cp => cp.label === CONTACT_POINT_LABELS.MOBILE.value).value;
    },
    otherPhone (patient) {
      return patient.contactPoints.find(cp => (cp.media === MEDIA_TYPES.TELEPHONE.value) && (cp.label === CONTACT_POINT_LABELS.OTHER.value)).value;
    },
    personalPhone (patient) {
      return patient.contactPoints
          .filter(cp => (cp.media === MEDIA_TYPES.TELEPHONE.value) && (cp.label === CONTACT_POINT_LABELS.PERSONAL.value)).value;
    },
    businessPhone (patient) {
      return patient.contactPoints
          .filter(cp => (cp.media === MEDIA_TYPES.TELEPHONE.value) && (cp.label === CONTACT_POINT_LABELS.BUSINESS.value)).value;
    },
    email (patient) {
      return patient.contactPoints.find(cp => (cp.media === MEDIA_TYPES.EMAIL.value) && ! cp.label).value;
    },
    getResetForms () {
      if ((this.mergeAdministrativePatientFolderFormKey > 0) && this.value.resetForms) {
        return false;
      }

      return this.value.resetForms;
    },
  },
};
extend('checkboxIsNotEmpty', {
  validate (value, { checkboxValues }) {
    return checkboxValues;
  },
  params: ['checkboxValues'],
});
</script>

<style scoped lang="scss">
.merge-administrative-folder {
  &__info {
    font-style: italic;
    color: var(--v-text-lighten3);
    font-weight: bolder;
    font-size: 0.9em;
  }
}
</style>