<template>
  <app-person-details
    :person="patient"
    v-bind="personDetailsMixin_props"
    :align-content-top="alignContentTop"
    class="patient-details"
  >
    <template
      v-if="redirectToPatientFile"
      #civil-state="{ civilState }"
    >
      <slot name="civil-state">
        <p
          v-if="showStrictTraits"
          class="patient-details__patient-civil-state-title"
        >
          Identité utilisée
        </p>
        <a
          v-test="'patient-file-link'"
          class="patient-details__civil-state-link"
          data-sentry-mask
          @click="goToPatientFile"
        >
          {{ civilState }}
        </a>
      </slot>
    </template>
    <template
      v-else-if="showPictograms"
      #civil-state="{ civilState }"
    >
      <span class="d-inline-block">
        <p
          v-if="showStrictTraits"
          class="patient-details__patient-civil-state-title"
        >
          Identité utilisée
        </p>
        <slot name="civil-state">
          <span data-sentry-mask>{{ civilState }}</span>
        </slot>
      </span>
      <app-icon
        v-test="'ins-status-icon'"
        :size="14"
        :color="patient.insIdentity.getColor()"
        icon="ins-status"
        :tooltip="patient.insIdentity.getLabel()"
        class="ml-2"
      />
      <app-icon
        v-if="! patient.mssDocumentConsent"
        :size="14"
        color="error"
        icon="attachment"
        :tooltip="PATIENT_MESSAGING_DOCUMENT_SENDING_DENIAL_TEXT"
        class="ml-2"
      />
      <app-icon
        v-if="patient.isDuplicated"
        v-test="'duplicated-icon'"
        :size="14"
        color="error"
        icon="duplicated"
        tooltip="Doublon potentiel"
        class="ml-2"
      />
    </template>
    <template #sub-content>
      <div class="flex-list-1 flex-column">
        <p
          v-if="showBirthInformations"
          v-test="'patient-birth-informations'"
          class="d-flex align-center"
        >
          <span data-sentry-mask>{{ patient.getFormattedBirthDate({ bornOn: true }) }} </span>
          <span
            v-if="!patient.isDeceased || patient.deathDate"
            class="secondary--text ml-1"
          >{{ patient.getAge() }}</span>
        </p>
        <p
          v-if="!showStrictTraits && patient.isDeceased && patient.deathDate"
          v-test="'patient-death-informations'"
          class="d-flex align-center"
        >
          <span data-sentry-mask>{{ patient.getFormattedDeathDate({ deadOn: true }) }}</span>
        </p>
        <div v-if="patient.gender === GENDERS.FEMALE.value && femaleMedicalDataset && femaleMedicalDataset.amenorrheaWeeks">
          Grossesse : {{ femaleMedicalDataset.amenorrheaWeeks }} SA
        </div>
        <app-dataset-item
          v-if="showAnonymizedIdentifier"
          v-test="'public-id'"
          label="Identifiant anonymisé"
          data-sentry-mask
          :value="patient.publicId"
        />
        <app-dataset-item
          v-if="! showStrictTraits && isBirthNameDisplayable"
          v-test="'birth-name'"
          label="Nom de naissance"
          data-sentry-mask
          :value="patient.birthName"
        />
        <patient-keywords-list
          v-if="canManageKeywords || (showKeywords && patient.keywords.length)"
          :patient="patient"
          :keywords="patient.keywords"
          :can-manage="canManageKeywords"
        />
        <slot name="sub-content" />
      </div>
    </template>
  </app-person-details>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import personDetailsMixin from '@/components/ui/personDetails/personDetailsMixin';
import { GENDERS } from '@/constants';
import { ROUTE_NAMES, PATIENT_MESSAGING_DOCUMENT_SENDING_DENIAL_TEXT } from '@/modules/patient/constants';
import Patient from '@/modules/patient/models/Patient';

import AppDatasetItem from '@/components/ui/datasetItem/AppDatasetItem.vue';
import AppPersonDetails from '@/components/ui/personDetails/AppPersonDetails.vue';
import PatientKeywordsList from '@/modules/patient/components/keywords/components/PatientKeywordsList.vue';



/**
 * Représente les détails d'un patient
 */
export default {
  name: 'PatientDetails',
  components: {
    AppDatasetItem,
    AppPersonDetails,
    PatientKeywordsList,
  },
  mixins: [personDetailsMixin],
  props: {
    /**
     * Le patient à afficher
     */
    patient: {
      type: Patient,
      required: true,
    },
    /**
     * Affiche les informations de naissance du patient (Date de naissance + age)
     */
    showBirthInformations: {
      type: Boolean,
      default: false,
    },
    /**
     * Affiche le nom de naissance du patient si celui-ci est différent du nom d'usage
     */
    completeNameInformations: {
      type: Boolean,
      default: false,
    },
    /**
     * Affiche la liste des mots-clefs du patient
     */
    showKeywords: {
      type: Boolean,
      default: true,
    },
    /**
     * Affiche l'identifiant anonyme du patient
     */
    showAnonymizedIdentifier: {
      type: Boolean,
      default: true,
    },
    /**
     * Affiche les pictogrammes d'informations complémentaires du patient
     */
    showPictograms: {
      type: Boolean,
      default: true,
    },
    /**
     * Autorise ou non la gestion des mots-clefs (ajout / suppression)
     */
    canManageKeywords: {
      type: Boolean,
      default: false,
    },
    /**
     * Permet d'accéder au dossier du patient au clic sur son état civil
     */
    redirectToPatientFile: {
      type: Boolean,
      default: false,
    },
    /**
     * Affiche les traits qui complète l'identité patient
     */
    showStrictTraits: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      PATIENT_MESSAGING_DOCUMENT_SENDING_DENIAL_TEXT,
      GENDERS,
    };
  },
  computed: {
    ...mapGetters('patient', ['getFemaleMedicalDataset', 'getHasLoadedFemaleMedicalDatasetByPatientUuid', 'isLoadingFemaleMedicalDataset']),
    femaleMedicalDataset () {
      return this.getFemaleMedicalDataset(this.patient.getUuid());
    },
    isBirthNameDisplayable () {
      return this.completeNameInformations && this.patient.familyName && (this.patient.familyName !== this.patient.birthName);
    },
  },
  watch: {
    'patient.femaleMedicalDataset': {
      immediate: true,
      async handler () {
        if (this.patient.femaleMedicalDataset && ! this.getHasLoadedFemaleMedicalDatasetByPatientUuid(this.patient.getUuid())) {
          this.fetchFemaleMedicalDataset(this.patient);
        }
      },
    },
  },
  methods: {
    ...mapActions('patient', ['fetchFemaleMedicalDataset']),
    goToPatientFile () {
      this.$router.push({
        name: ROUTE_NAMES.PATIENT_FILE,
        params: { uuid: this.patient.getUuid() },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.patient-details {
  &__civil-state-link {
    color: var(--v-color-text);

    &:hover {
      color: var(--v-primary-base);
      text-decoration: underline;
    }
  }
  &__patient-civil-state-title {
    font-size: 12px;
    font-weight: 400;
    color: var(--v-secondary-base);
  }
}
</style>