<template>
  <v-row dense class="merge-duplicates-fieldset">
    <v-col cols="3" align-self="center" class="merge-duplicates-fieldset__label">
      <slot /> <span class="merge-duplicates-fieldset__info">choix multiple</span>
    </v-col>
    <v-col cols="4">
      <v-checkbox
        v-model="mergedPatientField"
        :label="getPatientLabel"
        :value="patientField"
        :error-messages="errorMessage"
        dense
        @change="updateDisplayErrorMessage"
      />
    </v-col>
    <v-col cols="4">
      <v-checkbox
        v-model="mergedPatientField"
        :label="getSelectedTargetPatientLabel"
        :value="selectedTargetPatientField"
        :error-messages="errorMessage"
        dense
        @change="updateDisplayErrorMessage"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'MergeDuplicatesCheckbox',
  props: {
    patientField: {
      type: String,
      default: 'aucune valeur',
    },
    selectedTargetPatientField: {
      type: String,
      default: 'aucune valeur',
    },
    patientLabel: {
      type: String,
      default: null,
    },
    selectedTargetPatientLabel: {
      type: String,
      default: null,
    },
  },
  data () {
    return {
      displayErrorMessage: false,
      mergedPatientField: [],
    };
  },
  computed: {
    getPatientLabel () {
      return this.patientLabel ?? this.patientField ?? 'aucune valeur';
    },
    getSelectedTargetPatientLabel () {
      return this.selectedTargetPatientLabel ?? this.selectedTargetPatientField ?? 'aucune valeur';
    },
    errorMessage () {
      return this.displayErrorMessage ? 'Veuillez sélectionner au moins une option.' : '';
    },
  },
  watch: {
    patientField: {
      immediate: true,
      deep: true,
      handler (newFieldValue) {
        if (newFieldValue) {
          this.mergedPatientField.push(newFieldValue);
        }
      },
    },
    mergedPatientField: {
      deep: true,
      handler () {
        this.$emit('input', this.getMergedFields());
      },
    },
  },
  methods: {
    getMergedFields () {
      return (this.mergedPatientField || []).join(' - ');
    },
    updateDisplayErrorMessage () {
      this.displayErrorMessage = (this.mergedPatientField.length === 0);
      this.$emit('checkboxSelected', ! this.displayErrorMessage);
    },
  },
};
</script>

<style scoped lang="scss">
.merge-duplicates-fieldset {
  font-weight: 600;
  &__label {
    font-size: 1.1em;
  }
  &__info {
    font-style: italic;
    color: var(--v-text-lighten3);
    font-weight: lighter;
    font-size: 0.9em;
    margin-left: 5px;
  }
}
</style>