<template>
  <div class="pathology-autocomplete-widget-v2">
    <section ref="widget" />
  </div>
</template>

<script>
import widgetMixin from '@/components/lap/synapse-widgets/mixins/widget';
import { asPatientPathology } from '@/components/lap/synapse-widgets/utils/synapsePathologyDataMapper';

export default {
  name: 'PathologyAutocompleteWidgetV2',
  mixins: [widgetMixin],
  props: {
    favorites: {
      type: Array,
      default: () => [],
    },
  },
  async mounted() {
    await this.initWidget({
      terminologies: [
        {
          terminology: 'icd10',
          active: true,
          available: true,
        },
        {
          terminology: 'cisp',
          active: false,
          available: false,
        },
        {
          terminology: 'drc',
          active: false,
          available: false,
        },
      ],
      disableSelectedPathologies: true,
      enableFavorite: true,
      favorites: this.favorites,
    }, { zIndex: 204 });

    this.widget.setCallback('onSelectPathology', (pathology) => {
      this.$emit('input', asPatientPathology(pathology));
    });

    this.widget.setCallback('onAddFavorite', (pathology) => {
      this.$emit('add-favorite', pathology);
    });

    this.widget.setCallback('onDeleteFavorite', (pathologyIdentifier) => {
      this.$emit('delete-favorite', pathologyIdentifier);
    });
  },
};
</script>
<style lang="scss" scoped>
.pathology-autocomplete-widget-v2 {
  ::v-deep {
    .pathology-autocomplete {
      fieldset {
        label {
          outline: none !important;
          &:focus-within {
            border-bottom: 2px solid var(--v-primary-base);
          }
        }
      }
    }
  }
}
</style>