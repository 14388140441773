<template>
  <consultation-file-list
    :items-icon="hideItemIcons ? null : 'prescription'"
    :items="prescriptions"
    :consultation-uuid="consultationUuid"
    :patient-iri="patientIri"
    :dense="dense"
    :hide-actions="hidePrescriptionActions"
    :hide-base-actions="hideBaseActions"
    :allow-selection="allowSelection"
    @click:regenerate-pdf="regeneratePdf"
    @click:duplicate="duplicatePrescription"
    @click:edit="editPrescription"
    @click:download-ihe_xdm="downloadDocumentIheXdm"
    @selected="selectItem"
    @refresh="$emit('refresh')"
  >
    <template #no-data>
      Aucune ordonnance
    </template>

    <template #item-title="{ item }">
      <span class="d-flex align-center">
        <span class="subtitle-3">{{ item.name }}</span>
        <span v-if="! hasConsultationDate && hasCreatedByFullName">
          <span class="consultation-part__labelSeparator">&nbsp;créée par&nbsp;</span>
          <span class="subtitle-3">{{ getFormattedCreatedByFullName(item.createdByFullName) }}</span>
        </span>
        <n-tooltip
          v-if="item.isEprescription()"
          v-slot="{ on }"
          data-test="e-prescription-tag"
          text="Prescription dématérialisée"
        >
          <span v-on="on">
            <app-tag
              label
              x-small
              class="ml-2"
            >
              e-Prescription
            </app-tag>
          </span>
        </n-tooltip>
      </span>
      <span v-if="hasConsultationDate" class="d-flex align-center consultation-part__practitioner-subtitle">
        <span v-if="hasCreatedByFullName" class="consultation-part__labelSeparator">&nbsp;créée par&nbsp;</span>
        <span v-if="hasCreatedByFullName" class="subtitle-3">{{ getFormattedCreatedByFullName(item.createdByFullName) }}</span>
        <span class="consultation-part__labelSeparator">&nbsp;le&nbsp;</span>
        <span class="subtitle-3">{{ NovaTools.dates.format(item.consultationDate) }}</span>
      </span>
    </template>

    <template #item-description="{ item }">
      <div class="px-4 pt-2 mt-n2">
        <section
          v-if="item.actLines.length"
          v-test="'act-line-list'"
        >
          <p class="mb-1">
            <app-icon
              icon="medicine-act"
              :size="13"
              color="var(--v-secondary-base)"
              class="mr-1"
            /> Actes - {{
              item.getTypeText() }}
          </p>
          <ul class="mb-last-0">
            <li
              v-for="(actLine, alIndex) in item.actLines"
              :key="alIndex"
              class="consultation-part__details mb-1"
            >
              <span
                v-test="'act-label'"
                class="content--text"
              >
                {{ actLine.getLabel() }}
              </span>
              <ul class="consultation-part__details__infos">
                <li v-if="actLine.renewalCount">
                  {{ actLine.getRenewalDetails() }}
                </li>
              </ul>
            </li>
          </ul>
        </section>
        <section
          v-if="item.getSpecialtyMedicineLines().length"
          v-test="'medicine-line-list'"
        >
          <p class="mb-1 consultation-part__medicine-title">
            <app-icon
              icon="pills"
              :size="13"
              color="var(--v-secondary-base)"
              class="mr-1"
            /> Médicaments
          </p>
          <div
            v-for="(prescriptionLine, plIndex) in item.getSpecialtyMedicineLines()"
            :key="plIndex"
            class="consultation-part__details mb-1"
          >
            <patient-sidebar-panel-listing-item
              :title="prescriptionLine.getLabel()"
              :description="prescriptionLine.getComment()"
              :summaries="prescriptionLine.getSummaries({ withCountRenewal: true })"
              skip-title-formatting
            />
          </div>
        </section>
        <section
          v-if="item.getLppLines().length"
          v-test="'lpp-line-list'"
        >
          <p class="mb-1 mt-2 consultation-part__medicine-title">
            <app-icon
              icon="band-aid"
              :size="13"
              color="var(--v-secondary-base)"
              class="mr-1"
            /> Produits et prestations
          </p>
          <ul class="mb-last-0">
            <li
              v-for="(prescriptionLppLine, lppIndex) in item.getLppLines()"
              :key="lppIndex"
              class="consultation-part__details mb-1"
            >
              <p
                v-test="'lpp-label'"
                class="content--text"
              >
                {{ prescriptionLppLine.getLabel() }}
              </p>
              <p
                v-test="'lpp-description'"
                class="consultation-part__details__comment"
              >
                {{ prescriptionLppLine.getComment() }}
              </p>
              <ul class="consultation-part__details__infos">
                <li v-if="prescriptionLppLine.packageCount">
                  {{ prescriptionLppLine.getPackageDetails() }}
                </li>
                <li v-if="prescriptionLppLine.locationDayCount || prescriptionLppLine.purchase">
                  {{ prescriptionLppLine.getDurationDetails() }}
                </li>
                <li v-if="prescriptionLppLine.renewalCount">
                  {{ prescriptionLppLine.getRenewalDetails() }}
                </li>
              </ul>
            </li>
          </ul>
        </section>
        <section
          v-if="item.getParapharmacyLines().length"
          v-test="'parapharmacy-line-list'"
        >
          <p class="mb-1 mt-2 consultation-part__medicine-title">
            <app-icon
              icon="medical-bottle"
              :size="13"
              color="var(--v-secondary-base)"
              class="mr-1"
            /> Produits de
            parapharmacie
          </p>
          <ul class="mb-last-0">
            <li
              v-for="(prescriptionParapharmacyLine, parapharmacyIndex) in item.getParapharmacyLines()"
              :key="parapharmacyIndex"
              class="consultation-part__details mb-1"
            >
              <p
                v-test="'parapharmacy-title'"
                class="content--text"
              >
                {{ prescriptionParapharmacyLine.title }}
              </p>
              <p
                v-test="'parapharmacy-description'"
                class="consultation-part__details__infos"
              >
                {{ prescriptionParapharmacyLine.getBrand() }} {{ prescriptionParapharmacyLine.code }}
              </p>
              <p class="consultation-part__details__comment">
                {{ prescriptionParapharmacyLine.comment }}
              </p>
              <ul class="consultation-part__details__infos">
                <li>
                  {{ prescriptionParapharmacyLine.getPackageDetails() }}
                </li>
              </ul>
            </li>
          </ul>
        </section>
        <section
          v-if="item.compoundedMedicineLines.length"
          v-test="'lpp-line-list'"
        >
          <p class="mb-1 mt-2 consultation-part__medicine-title">
            <app-icon
              icon="medicine-compounded"
              :size="13"
              color="var(--v-secondary-base)"
              class="mr-1"
            /> Préparations
            magistrales
          </p>
          <ul class="mb-last-0">
            <li
              v-for="(pLine, index) in item.compoundedMedicineLines"
              :key="index"
              class="consultation-part__details mb-1"
            >
              <p
                v-test="'lpp-label'"
                class="content--text"
              >
                {{ pLine.toString() }}
              </p>
              <ul class="consultation-part__details__infos">
                <li v-if="pLine.renewalCount">
                  {{ pLine.getRenewalDetails() }}
                </li>
              </ul>
            </li>
          </ul>
        </section>
      </div>
    </template>
  </consultation-file-list>
</template>

<script>
import { mapMutations } from 'vuex';

import ConsultationFileList from '../ConsultationFileList.vue';
import { ROUTE_NAMES } from '@/modules/patient/constants';
import NovaTools from '@/nova-tools/NovaTools';
import { getFileFromAPI, putToAPI } from '@/services/api';
import ZipFile from '@/utils/classes/files/ZipFile';
import { downloadBlob } from '@/utils/functions/files';
import { getCivilityShortLabel } from '@/utils/functions/refs';

import AppTag from '@/components/ui/tag/AppTag.vue';
import PatientSidebarPanelListingItem from '@/modules/patient/components/PatientSidebarPanelListingItem.vue';

export default {
  name: 'ConsultationPrescriptionsList',
  components: {
    ConsultationFileList,
    AppTag,
    PatientSidebarPanelListingItem,
  },
  props: {
    consultationUuid: {
      type: String,
      default: null,
    },
    patientIri: {
      type: String,
      required: true,
    },
    prescriptions: {
      type: Array,
      required: true,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    hideItemIcons: {
      type: Boolean,
      default: false,
    },
    /**
     * Cache les actions possibles sur la prescription
     * Comme regenerer ou dupliquer
     */
    hidePrescriptionActions: {
      type: Boolean,
      default: false,
    },
    /**
     * Cache les actions de base
     * Comme télécharger
     */
    hideBaseActions: {
      type: Boolean,
      default: false,
    },
    /**
     * Permet de choisir une ordonnance
     * Cette ordonnance est renvoyée via l'event selected
     */
    allowSelection: {
      type: Boolean,
      default: false,
    },
    hasCreatedByFullName: {
      type: Boolean,
      default: false,
    },
    hasConsultationDate: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      isRegenerating: false,
      NovaTools,
    };
  },
  methods: {
    ...mapMutations('app', { setSnack: 'SET_SNACK' }),
    ...getCivilityShortLabel,
    async regeneratePdf (prescription) {
      const { data } = await putToAPI(prescription.getIri(), { data: {} });
      this.$emit('update:prescription', data);
      this.setSnack({ message: 'L\'ordonnance a été régénérée' });
    },
    editPrescription (prescription) {
      this.$router.push({
        name: ROUTE_NAMES.EDIT_PRESCRIPTION,
        params: {
          consultationUUID: this.consultationUuid,
          prescriptionUuid: prescription.getUuid(),
          backRoute: this.$router.currentRoute.path,
        },
      });
    },
    async duplicatePrescription (prescription) {
      if (! this.isRegenerating) {
        this.isRegenerating = true;
        const { data } = await getFileFromAPI(`${prescription.getIri()}/duplicate`);
        downloadBlob(data, { fileName: `${prescription.name}_duplicata.pdf` });
        this.isRegenerating = false;
      }
    },
    async downloadDocumentIheXdm (document) {
      await new ZipFile(`${document['@id']}/ihe_xdm`).download();
    },
    selectItem (item) {
      this.$emit('selected', item);
    },
    getFormattedCreatedByFullName (createdByFullName) {
      if (! createdByFullName) {
        return createdByFullName;
      }
      const [prefix, ...rest] = createdByFullName.trim().split(/\s+/);
      const formattedCivility = getCivilityShortLabel(prefix.toLowerCase());
      return [(formattedCivility ?? prefix), ...rest].join(' ');
    },
  },
};
</script>

<style lang="scss" scoped>
.consultation-part {
  &__details {
    &__comment,
    &__infos {
      color: var(--v-secondary-base);
      font-size: 12px;
    }
  }

  &__line {
    display: grid;
  }

  &__medicine-title {
    color: var(--v-secondary-base);
    font-size: 12px;
  }

  &__label-separator {
    font-weight: normal;
  }

  &__practitioner-subtitle {
    margin-left: -4px;
  }
}

.prescription-active-list {
  font-size: 12px;
}
</style>