import ApiEntity from '@/models/ApiEntity';
import NovaTools from '@/nova-tools/NovaTools';
import { getFromAPI } from '@/services/api';
import { add, format, isFuture } from '@/utils/functions/dates';

export default class PatientFemaleMedicalDataset extends ApiEntity {
  constructor (options = {}) {
    super(options);
    this.patient = options.patient || null;
    this.pregnant = options.pregnant || false;
    this.breastfeeding = options.breastfeeding || false;
    this.lastPeriodDate = options.lastPeriodDate ? format(options.lastPeriodDate, 'yyyy-MM-dd') : null;
    this.conceptionDate = options.conceptionDate ? format(options.conceptionDate, 'yyyy-MM-dd') : this.getDefaultConceptionDate();
    this.deliveryDate = options.deliveryDate ? format(options.deliveryDate, 'yyyy-MM-dd') : this.getDefaultDeliveryDate();
    this.amenorrheaWeeks = options.amenorrheaWeeks || null;
  }

  getDefaultConceptionDate () {
    const conceptionDate = (this.lastPeriodDate ? format(add(new Date(this.lastPeriodDate), { days: 14 }), 'yyyy-MM-dd') : null);
    if (! isFuture(new Date(conceptionDate))) {
      return conceptionDate;
    }
    return null;
  }

  getDefaultDeliveryDate () {
    return (this.conceptionDate ? format(add(new Date(this.conceptionDate), { days: 275 }), 'yyyy-MM-dd') : null);
  }

  static async fetch (uuid) {
    const { data } = await getFromAPI(uuid);
    return new PatientFemaleMedicalDataset(data);
  }

  getBooleanLabel (value) {
    if (typeof value === 'boolean') {
      return value ? 'oui' : 'non';
    }
    return null;
  }

  getFormattedDate (date) {
    return date ? NovaTools.dates.format(date, 'dd/MM/yyyy') : null;
  }

  getPregnantFullLabel () {
    const pregnantElems = [
      this.getBooleanLabel(this.pregnant) ? `Grossesse : ${this.getBooleanLabel(this.pregnant)}` : null,
      this.getFormattedDate(this.lastPeriodDate) ? `Dernières règles : ${this.getFormattedDate(this.lastPeriodDate)}` : null,
      this.getFormattedDate(this.conceptionDate) ? `Conception : ${this.getFormattedDate(this.conceptionDate)}` : null,
      this.getFormattedDate(this.deliveryDate) ? `Accouchement : ${this.getFormattedDate(this.deliveryDate)}` : null,
    ].filter(pregnantElem => pregnantElem);

    return (pregnantElems.length === 0) ? null : pregnantElems;
  }
}