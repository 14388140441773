/**
 * @see {@link https://www.figma.com/file/ZmkBPFBI8YMQlwnA13gS0G/Novalys?node-id=1%3A6}
 */
export const ICONS = Object.freeze({
  add: 'fas fa-plus',
  address: 'fas fa-map-marker-alt',
  'adverse-effect': 'fas fa-heart-circle-xmark',
  archive: 'fas fa-box-archive',
  attach: 'fas fa-paperclip',
  attachment: 'fas fa-paperclip',
  ban: 'fas fa-ban',
  'band-aid': 'fas fa-band-aid',
  camera: 'fas fa-camera',
  caret: 'fas fa-caret-down',
  certificate: 'fa fa-stamp',
  chart: 'fas fa-chart-line',
  check: 'fas fa-check',
  'check-circle': 'fas fa-check-circle',
  'chevron-down': 'fas fa-chevron-down',
  'chevron-up': 'fas fa-chevron-up',
  clock: 'fas fa-clock',
  'clock-outlined': 'far fa-clock',
  close: 'fas fa-times',
  'close-alt': 'fa fa-circle-xmark',
  'cloud-upload': 'fa-solid fa-cloud-arrow-up',
  consultation: 'fas fa-stethoscope',
  copy: 'fas fa-clone',
  cpx: 'fa-solid fa-address-card',
  delete: 'fas fa-trash',
  document: 'fa fa-file-alt',
  download: 'fa fa-download',
  dna: 'fas fa-dna',
  dmp: 'fas fa-laptop-file',
  duplicated: 'fas fa-user-friends',
  edit: 'fas fa-pen',
  email: 'fas fa-envelope',
  error: 'fa fa-exclamation-triangle',
  euro: 'mdi-currency-eur',
  expand: 'fas fa-up-right-and-down-left-from-center',
  export: 'fas fa-file-export',
  fax: 'fas fa-fax',
  female: 'fas fa-venus',
  file: 'fas fa-file',
  'file-alt': 'far fa-file-alt',
  'file-pdf': 'fas fa-file-pdf',
  'file-question': 'fas fa-file-circle-question pl-1',
  'file-zip': 'fas fa-file-zipper',
  filters: 'fas fa-sliders-h',
  folder: 'fa fa-folder',
  'folder-add': 'fa fa-folder-plus',
  'folder-open': 'fa fa-folder-open',
  hide: 'fa-solid fa-eye-slash',
  info: 'fa-solid fa-circle-info',
  ins: 'fa fa-id-card',
  'ins-status': 'fas fa-clipboard-check',
  image: 'fas fa-image',
  keyword: 'fas fa-tag',
  left: 'fas fa-arrow-left',
  lock: 'fa-solid fa-lock',
  lockOpen: 'fa-solid fa-lock-open',
  logs: 'fas fa-server',
  'magnifying-glass-bolt': 'fab fa-searchengin',
  'magnifying-glass-plus': 'fas fa-magnifying-glass-plus',
  male: 'fas fa-mars',
  'map-marker': 'fas fa-map-marker-alt',
  'medical-bottle': 'fas fa-prescription-bottle-medical',
  'medicine-compounded': 'fas fa-mortar-pestle',
  'medicine-act': 'fas fa-clipboard-list',
  menu: 'fas fa-ellipsis-v',
  'menu-alt': 'fas fa-bars',
  'messaging-transfert': 'mdi-email-fast',
  minus: 'fas fa-minus',
  mobile: 'fas fa-mobile-alt',
  move: 'fas fa-arrows-alt',
  'not-refundable': 'fa-brands fa-creative-commons-nc-eu',
  note: 'fas fa-clipboard',
  outbox: 'fas fa-paper-plane',
  pathology: 'fas fa-microscope',
  person: 'fas fa-person',
  phone: 'fas fa-phone',
  pills: 'fa fa-pills',
  practitioner: 'fas fa-user-md',
  prescription: 'fa fa-file-contract',
  'prescription-template': 'fas fa-star',
  qrcode: 'fas fa-qrcode',
  reply: 'fas fa-reply',
  right: 'fas fa-arrow-right',
  rotate: 'fa fa-arrows-rotate',
  schedule: 'fa fa-calendar',
  'schedule-day': 'fas fa-calendar-day',
  'schedule-outlined': 'far fa-calendar',
  'schedule-plus': 'fa fa-calendar-plus',
  search: 'fas fa-search',
  settings: 'fas fa-cog',
  spinner: 'fas fa-spinner',
  star: 'fas fa-star',
  success: 'fa fa-check-circle',
  syringe: 'fas fa-syringe',
  tag: 'fas fa-tag',
  telemedicine: 'fa fa-headset',
  teleservices: 'fas fa-laptop-medical',
  'time-left': 'fa fa-clock-rotate-left',
  undo: 'fas fa-undo',
  'unfilled-star': 'far fa-star',
  unknown: 'fas fa-question',
  upload: 'fas fa-upload',
  user: 'fas fa-user',
  'user-check': 'fas fa-user-check',
  'user-times': 'fas fa-user-times',
  userPlus: 'fas fa-user-plus',
  userShield: 'fa fa-user-shield',
  users: 'fas fa-users',
  userTag: 'fas fa-user-tag',
  visualize: 'fas fa-eye',
  warning: 'fa fa-exclamation-circle',
});