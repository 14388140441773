import Contact from './Contact';
import PatientMeasureUserSetting from './PatientMeasureUserSetting';
import RoleRef from './RoleRef';
import ApiEntity from '@/models/ApiEntity';
import { getFromAPI } from '@/services/api';

export default class User extends ApiEntity {
  constructor (options = {}) {
    super(options);
    this.isAdmin = options.isAdmin || null;
    this.username = options.username || null;
    this.roleRefs = options.roleRefs
      ? options.roleRefs.map(roleRef => new RoleRef(roleRef))
      : [];
    this.patientMeasureUserSettings = options.patientMeasureUserSettings
      ? options.patientMeasureUserSettings.map(
        patientMeasureUserSetting => new PatientMeasureUserSetting(patientMeasureUserSetting),
      ) : [];
    this.contact = options.contact ? new Contact(options.contact) : null;
  }

  static async getPractitionersWithPrescriptionTemplates () {
    const { data } = await getFromAPI('/api/with_prescription_templates/users');
    return data['hydra:member'];
  }

  static async getPractitionersWithPatientPrescriptions (patientId) {
    const { data } = await getFromAPI(`/api/with_prescription/${patientId}/users`);
    return data['hydra:member'];
  }
}