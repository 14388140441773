<template>
  <n-dialog
    :key="mergeDuplicatesModalKey"
    divided
    persistent
    content-class="merge-duplicates-modal"
    :is-open="isOpen"
    :scrollable="scrollable"
    title="Fusionner un doublon"
    width="75vw"
    @update:is-open="isOpen => $emit('update:is-open', isOpen)"
    @close="closeModal"
  >
    <app-stepped-form
      panel
      :forms="forms"
    />
  </n-dialog>
</template>

<script>
import { clone } from 'lodash';
import { mapActions } from 'vuex';

import { CIVILITIES, CONTACT_POINT_LABELS, FAMILY_SITUATIONS, GENDERS, MEDIA_TYPES } from '@/constants';
import { ROUTE_NAMES } from '@/modules/patient/constants';
import Patient from '@/modules/patient/models/Patient';
import NovaTools from '@/nova-tools/NovaTools';
import { getFromAPI } from '@/services/api';

import AppSteppedForm from '@/components/ui/form/AppSteppedForm.vue';
import MergeAdministrativePatientFolderForm
  from '@/modules/patient/components/sidebar/duplicates/forms/MergeAdministrativePatientFolderForm.vue';
import MergeMedicalPatientFolderForm
  from '@/modules/patient/components/sidebar/duplicates/forms/MergeMedicalPatientFolderForm.vue';
import MergeProcessSummaryForm from '@/modules/patient/components/sidebar/duplicates/forms/MergeProcessSummaryForm.vue';
import SelectDuplicatePatientForm
  from '@/modules/patient/components/sidebar/duplicates/forms/SelectDuplicatePatientForm.vue';

export default {
  name: 'MergeDuplicatesModal',
  components: { AppSteppedForm },
  props: {
    patient: {
      type: Patient,
      required: true,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    scrollable: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      mergeDuplicatesModalKey: 0,
      duplicatePatients: [],
      selectedTargetPatient: null,
      mergedPatient: null,
      lastSelectedTargetPatientId: null,
      resetForms: false,
      displayedFields: [],
    };
  },
  computed: {
    forms () {
      return [
        {
          form: SelectDuplicatePatientForm,
          value: {
            patient: this.getPatient(),
            selectedTargetPatient: this.selectedTargetPatient,
            duplicates: this.duplicatePatients,
            lastSelectedTargetPatientId: this.lastSelectedTargetPatientId,
            resetForms: this.resetForms,
          },
          stepName: 'Sélectionner le dossier en double',
          submitText: 'Suivant',
          onSuccess: (selectedTargetPatient) => this.addSelectedTargetPatient(selectedTargetPatient),
        },
        {
          form: MergeAdministrativePatientFolderForm,
          value: {
            patient: this.getPatient(),
            selectedTargetPatient: this.selectedTargetPatient,
            mergedPatient: this.mergedPatient,
            lastSelectedTargetPatientId: this.lastSelectedTargetPatientId,
            resetForms: this.resetForms,
          },
          stepName: 'Fusionner les données administratives',
          submitText: 'Suivant',
          onSuccess: (newMergedPatient) => this.updateMergedPatient(newMergedPatient),
        },
        {
          form: MergeMedicalPatientFolderForm,
          value: {
            patient: this.getPatient(),
            selectedTargetPatient: this.selectedTargetPatient,
            mergedPatient: this.mergedPatient,
            lastSelectedTargetPatientId: this.lastSelectedTargetPatientId,
            resetForms: this.resetForms,
          },
          stepName: 'Fusionner les données médicales',
          submitText: 'Valider',
          onSuccess: (newMergedPatient) => this.updateMergedPatient(newMergedPatient),
        },
        {
          form: MergeProcessSummaryForm,
          value: {
            formattedMergedPatient: this.getFormattedMergedPatient,
            mergedPatient: this.mergedPatient,
            selectedTargetPatient: this.selectedTargetPatient,
          },
          stepName: 'Vérification',
          submitText: 'Confirmer',
          onSuccess: () => this.sendMergedPatient(),
        },
      ];
    },
    getFormattedMergedPatient () {
      return [
        {
          label: 'Civilité',
          value: CIVILITIES[this.mergedPatient?.civility?.toUpperCase()]?.shortLabel,
        },
        {
          label: 'Nom utilisé',
          value: this.mergedPatient.familyName,
        },
        {
          label: 'Prénom utilisé',
          value: this.mergedPatient.usedFirstName,
        },
        {
          label: 'Numéro de sécurité sociale',
          value: this.mergedPatient.nir,
        },
        {
          label: 'Téléphone mobile',
          value: this.mergedPatient.contactPoints.find(cp => cp.label === CONTACT_POINT_LABELS.MOBILE.value).value,
        },
        {
          label: 'Autre téléphone',
          value: this.mergedPatient.contactPoints
              .find(cp => (cp.media === MEDIA_TYPES.TELEPHONE.value) && (cp.label === CONTACT_POINT_LABELS.OTHER.value)).value,
        },
        {
          label: 'Téléphone personnel',
          value: this.mergedPatient.contactPoints
              .filter(cp => (cp.media === MEDIA_TYPES.TELEPHONE.value) && (cp.label === CONTACT_POINT_LABELS.PERSONAL.value)).value,
        },
        {
          label: 'Téléphone professionnel',
          value: this.mergedPatient.contactPoints
              .filter(cp => (cp.media === MEDIA_TYPES.TELEPHONE.value) && (cp.label === CONTACT_POINT_LABELS.BUSINESS.value)).value,
        },
        {
          label: 'Email',
          value: this.mergedPatient.contactPoints.find(cp => (cp.media === MEDIA_TYPES.EMAIL.value) && ! cp.label).value,
        },
        {
          label: 'Adresse',
          value: this.getAddressLabel(this.mergedPatient.address),
        },
        {
          label: 'Situation familiale',
          value: FAMILY_SITUATIONS[this.mergedPatient.familySituation?.toUpperCase()]?.label,
        },
        {
          label: 'Profession(s)',
          value: this.mergedPatient.profession,
        },
        {
          label: 'Notes',
          value: this.mergedPatient.note,
        },
        {
          label: 'Date de décès',
          value: this.mergedPatient.deathDate ? this.mergedPatient.getFormattedDeathDate() : null,
        },
        {
          label: 'Groupe sanguin',
          value: this.mergedPatient.medicalDataset?.bloodType,
        },
        {
          label: 'Données liées à la grossesse',
          value: this.getPregnantFullLabel(this.mergedPatient.femaleMedicalDataset),
        },
        {
          label: 'Allaitement',
          value: this.getFemaleMedicalDatasetBreastfeeding(),
        },
        {
          label: 'Médecin traitant',
          value: this.mergedPatient.referringPhysician
              // eslint-disable-next-line max-len
              ? `${CIVILITIES[this.mergedPatient.referringPhysician.civility?.toUpperCase()]?.shortLabel} ${this.mergedPatient.referringPhysician.firstNames} ${this.mergedPatient.referringPhysician.familyName}`
              : null,
        },
      ].filter(elem => this.displayedFields.includes(elem.label));
    },
  },
  watch: {
    isOpen: {
      immediate: true,
      async handler (isOpen) {
        if (isOpen) {
          await this.getDuplicatePatients();
        }
      },
    },
    patient: {
      immediate: true,
      deep: true,
      handler () {
        if (this.patient) {
          this.mergedPatient = new Patient(this.getPatient());
        }
      },
    },
  },
  methods: {
    ...mapActions('patient', [
      'fetchPatientPathologies',
      'fetchPatientDrugAllergies',
      'fetchPatientMeasures',
      'fetchOngoingMedications',
      'fetchFemaleMedicalDataset'
    ]),
    getFemaleMedicalDatasetBreastfeeding () {
      const isBreastfeeding = this.mergedPatient?.femaleMedicalDataset?.breastfeeding ? 'oui' : 'non';
      return this.mergedPatient?.femaleMedicalDataset ? isBreastfeeding : null;
    },
    async getDuplicatePatients () {
      try {
        const { data } = await getFromAPI(`/api/get_duplicates/${this.patient.getUuid()}/patients`);
        data['hydra:member'].forEach((patient) => {
          this.duplicatePatients.push(new Patient(patient));
        });
      } catch (error) {
        this.$emit('update:is-open', false);
        NovaTools.notify.error('Une erreur s\'est produite lors de la récupération des doublons.');
      }
    },
    getPatient () {
      return clone(this.patient);
    },
    addSelectedTargetPatient (selectedTargetPatient) {
      this.selectedTargetPatient = selectedTargetPatient.patient;
      this.resetForms = selectedTargetPatient.resetForms;

      if (this.resetForms) {
        this.mergedPatient = new Patient(this.getPatient());
      }
    },
    getPregnantFullLabel (femaleMedicalDataset) {
      if (! femaleMedicalDataset || typeof femaleMedicalDataset !== 'object') {
        return null;
      }

      return femaleMedicalDataset.getPregnantFullLabel();
    },
    getAddressLabel (address) {
      if (! address || typeof address !== 'object') {
        return null;
      }

      return address.getAddressLabel();
    },
    updateMergedPatient (newMergedPatient) {
      this.mergedPatient = newMergedPatient.patient;
      this.lastSelectedTargetPatientId = newMergedPatient.lastSelectedTargetPatientId;
      this.resetForms = newMergedPatient.resetForms;
      this.displayedFields.push(...newMergedPatient.displayedFields);
    },
    async sendMergedPatient () {
      this.closeModal();
      this.$emit('update:is-open', false);
      await this.fetchPatientDrugAllergies(this.patient.getUuid());
      await this.fetchPatientPathologies(this.patient.getUuid());
      await this.fetchPatientMeasures(this.patient.getIri());
      await this.fetchOngoingMedications(this.patient.getUuid());
      if (this.patient.gender === GENDERS.FEMALE.value) {
        await this.fetchFemaleMedicalDataset(this.patient);
      }
      await this.$router.push({ name: ROUTE_NAMES.LISTING });
    },
    closeModal () {
      this.duplicatePatients = [];
      this.selectedTargetPatient = null;
      this.mergedPatient = this.patient;
      this.resetForms = false;
      this.lastSelectedTargetPatientId = null;
      this.mergeDuplicatesModalKey += 1;
    },
  },
};
</script>

<style lang="scss">
.merge-duplicates-modal {
  min-height: 70%;
  .vue-portal-target {
    padding-bottom: 12px;
  }
}
</style>