<template>
  <section class="medical-history">
    <div class="medical-history__sticky-zone">
      <template v-if="! isMobile">
        <header
          v-test="'headline'"
          class="d-flex justify-space-between align-center"
        >
          <h2 class="medical-history__title overflow-hidden">
            <n-truncable-text>
              Historique médical
            </n-truncable-text>
          </h2>
        </header>
      </template>
      <div>
        <medical-history-filters
          ref="medical-history-filters"
          class="medical-history__filters"
          @change:filtering="onUpdateFilters"
        />
      </div>
      <div class="flex-list-3">
        <create-note-button :patient="patient" />
      </div>
    </div>
    <infinite-scrolling-wrapper
      ref="medical-history-scroller"
      class="medical-history__items"
      :filters="medicalHistoryFilters"
      :item-per-page="5"
      :item-model-class="MedicalHistoryItem"
      :api-resource-url="medicalHistoryItemsRessourceUrl"
      @fetch-page="addConsultationsToStore"
    >
      <template #default="{ items, hasInitializedContent }">
        <div
          v-if="hasInitializedContent && items.length === 0"
          v-test="'no-results-illustrations'"
          class="medical-history__items__no-results"
        >
          <svg
            width="200"
            class="mb-6 icon-empty-medical-history"
          >
            <use xlink:href="#icon-empty-medical-history" />
          </svg>
          <p class="subtitle-1">
            {{ medicalHistoryFilters.length ? 'Aucun résultat trouvé pour votre recherche' : ' Aucune donnée à afficher' }}
          </p>
          <p class="secondary--text">
            Des résultats apparaîtront à l’ajout d’évènements telles que des consultations ou des notes
          </p>
        </div>
        <app-timeline
          v-else
          :items="items"
          :loading="! hasInitializedContent"
        >
          <template #item-skeleton>
            <medical-history-item-skeleton />
          </template>
          <template #default="{ item }">
            <medical-history-consultation
              v-if="item.isConsultation()"
              :consultation="item.item"
            />
            <medical-history-note
              v-if="item.isNote()"
              :note="item.item"
            />
          </template>
        </app-timeline>
      </template>
      <template #loading-component>
        <app-circular-loader
          label="Récupération des évènements"
          class="mt-6"
        />
      </template>
    </infinite-scrolling-wrapper>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import MedicalHistoryItem from '@/modules/patient/models/MedicalHistoryItem';
import Patient from '@/modules/patient/models/Patient';

import InfiniteScrollingWrapper from '@/components/ui/infiniteScrollingWrapper/InfiniteScrollingWrapper.vue';
import AppCircularLoader from '@/components/ui/loaders/AppCircularLoader.vue';
import AppTimeline from '@/components/ui/timeline/AppTimeline.vue';
import CreateNoteButton from '@/modules/patient/modules/patientFile/modules/overview/components/medicalHistory/CreateNoteButton.vue';
import MedicalHistoryFilters from '@/modules/patient/modules/patientFile/modules/overview/components/medicalHistory/MedicalHistoryFilters.vue';
import MedicalHistoryConsultation from '@/modules/patient/modules/patientFile/modules/overview/components/medicalHistory/medicalHistoryItems/MedicalHistoryConsultation.vue';
import MedicalHistoryItemSkeleton from '@/modules/patient/modules/patientFile/modules/overview/components/medicalHistory/medicalHistoryItems/MedicalHistoryItemSkeleton.vue';
import MedicalHistoryNote from '@/modules/patient/modules/patientFile/modules/overview/components/medicalHistory/medicalHistoryItems/MedicalHistoryNote.vue';



export default {
  name: 'MedicalHistory',
  components: {
    AppCircularLoader,
    AppTimeline,
    MedicalHistoryFilters,
    MedicalHistoryConsultation,
    MedicalHistoryNote,
    MedicalHistoryItemSkeleton,
    CreateNoteButton,
    InfiniteScrollingWrapper,
  },
  props: {
    patient: {
      type: Patient,
      required: true,
    },
  },
  data () {
    return {
      MedicalHistoryItem,
      medicalHistoryFilters: [],
    };
  },
  computed: {
    ...mapGetters('app', ['isMobile']),
    ...mapGetters('patient', ['getPatientMedicalHistoryNotes']),
    medicalHistoryNotes () {
      return this.getPatientMedicalHistoryNotes(this.patientUuid);
    },
    patientUuid () {
      return this.patient.getUuid();
    },
    medicalHistoryItemsRessourceUrl () {
      return `/api/patients/${this.patientUuid}/medical_history_items`;
    },
  },
  watch: {
    medicalHistoryNotes () {
      this.$refs['medical-history-scroller'].localInfiniteScroller.reset();
    },
  },
  methods: {
    ...mapActions('patient', ['deepCommitPatientConsultation']),
    onUpdateFilters (filters) {
      this.medicalHistoryFilters = filters.filter(filter => !! filter.value);
    },
    clearFilters () {
      this.medicalHistoryFilters = [];
      this.$refs['medical-history-filters'].clearFilters();
    },
    async addConsultationsToStore (medicalHistoryItems) {
      medicalHistoryItems
        .filter(item => item.type === 'consultation')
        .map(item => item.item)
        .forEach(async consultation => {
          await this.deepCommitPatientConsultation(consultation);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.medical-history {
  position: relative;
  display: flex;
  flex-direction: column;

  &__filters {
    ::v-deep {
      .v-input__slot {
        background-color: var(--v-secondary-lighten5);

        @include media-md {
          background-color: white;
        }
      }
    }
  }

  &__sticky-zone {
    position: sticky;
    top: 88px;
    left: 0;
    z-index: 1;
    padding: map-get($spacers, 4);
    margin: -#{map-get($spacers, 4)} -#{map-get($spacers, 4)} map-get($spacers, 4);
    background-color: white;

    display: flex;
    flex-direction: column;
    gap: map-get($spacers, 4);

    @include media-md {
      top: -20px;
      margin: -#{map-get($spacers, 4)} -#{map-get($spacers, 3)} 0 0;
      padding: map-get($spacers, 5) map-get($spacers, 3) map-get($spacers, 6) 0;
      background-color: var(--v-secondary-lighten5);
    }
  }

  &__title {
    font-size: 20px;
    font-weight: 600;
  }

  &__items {
    height: 100%;
    &__no-results {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }
}
</style>