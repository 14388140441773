<template>
  <synapse-autocomplete-wrapper
    :synapse-input-widget="MedicalResourceAutocompleteWidget"
    :value="value"
    :favorites="favorites"
    :enable-favorite="enableFavorite"
    :item-text="getDrugLabel"
    :item-value="getDrugValue"
    label="Rechercher un traitement"
    hide-label
    :rules="rules"
    :append-icon="appendIcon"
    class="drug-autocomplete"
    @input="onItemSelected"
    @add-favorite="$emit('add-favorite', $event)"
    @delete-favorite="$emit('delete-favorite', $event)"
  />
</template>

<script>
import MedicalResourceAutocompleteWidget from '@/components/lap/synapse-widgets/MedicalResourceAutocompleteWidget.vue';
import SynapseAutocompleteWrapper from '@/components/lap/SynapseAutocompleteWrapper.vue';

export default {
  name: 'DrugAutocomplete',
  components: { SynapseAutocompleteWrapper },
  props: {
    value: {
      type: Object,
      default: null,
    },
    favorites: {
      type: Array,
      default: () => [],
    },
    appendIcon: {
      type: String,
      default: 'search',
    },
    rules: {
      type: String,
      default: null,
    },
    enableFavorite: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      MedicalResourceAutocompleteWidget,
    };
  },
  methods: {
    onItemSelected (drug) {
      this.$emit('input', drug);
    },
    getDrugLabel (drug) {
      return drug.label;
    },
    getDrugValue (drug) {
      return drug.uuid ?? drug.drug_id ?? drug.uri;
    },
  },
};
</script>