<template>
  <app-form :submit-method="submit">
    <div v-if="loading" class="mb-last-0">
      <div
        v-for="(index) in DEFAULT_CORRESPONDENTS_SKELETONS_AMOUNT"
        :key="index"
        v-test="'merge-medical-folder-skeleton'"
        class="mb-4"
      >
        <app-skeleton-loader type="text" width="40%" />
        <app-skeleton-loader type="text" width="60%" />
      </div>
    </div>
    <v-simple-table v-else dense>
      <thead>
        <tr>
          <th class="text-left">
            Label
          </th>
          <th class="text-left">
            Valeur
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in value.formattedMergedPatient" :key="item.label">
          <td v-if="item.value">
            {{ item.label }}
          </td>
          <td v-if="Array.isArray(item.value)">
            <span v-for="subItem in item.value" :key="subItem">
              {{ subItem }}<br>
            </span>
          </td>
          <td v-else-if="! Array.isArray(item.value)">
            {{ item.value }}
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <app-confirm-modal
      title="Archivage du patient"
      content-text="Un des deux patients en double était archivé, que souhaitez-vous faire ?"
      :is-open.sync="isArchivedAlertOpen"
    >
      <template #actions>
        <app-button class="mr-2" color="primary" @click="updatePatientArchivedState(false)">
          Ne pas archiver
        </app-button>
        <app-button color="error" @click="updatePatientArchivedState(true)">
          Archiver
        </app-button>
      </template>
    </app-confirm-modal>
  </app-form>
</template>

<script>
import { getApiSerializedPatient } from '@/modules/patient/utils/getApiSerializedPatient';
import NovaTools from '@/nova-tools/NovaTools';
import { postToAPI } from '@/services/api';
import { getUUIDFromIRI } from '@/utils/functions/getUUIDFromIRI';
import { LocalCopyMixin } from '@novalys/mixins';

import AppForm from '@/components/ui/form/AppForm.vue';
import AppSkeletonLoader from '@/components/ui/loaders/AppSkeletonLoader.vue';
import AppConfirmModal from '@/components/ui/modal/AppConfirmModal.vue';

const DEFAULT_CORRESPONDENTS_SKELETONS_AMOUNT = 4;

export default {
  name: 'MergeProcessSummaryForm',
  components: {
    AppConfirmModal,
    AppSkeletonLoader,
    AppForm,
  },
  mixins: [
    LocalCopyMixin({
      copyPropertyName: 'mergedPatient',
      propertyName: 'value.mergedPatient',
    }),
  ],
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      DEFAULT_CORRESPONDENTS_SKELETONS_AMOUNT,
      loading: true,
      isArchivedAlertOpen: false,
    };
  },
  watch: {
    'value.formattedMergedPatient': {
      immediate: true,
      deep: true,
      handler () {
        this.loading = false;
      },
    },
  },
  methods: {
    patientArchivedStateUpdated () {
      return new Promise(resolve => {
        const interval = setInterval(() => {
          if (! this.isArchivedAlertOpen) {
            clearInterval(interval);
            resolve();
          }
        }, 500);
      });
    },
    updatePatientArchivedState (value) {
      this.mergedPatient.archived = value;
      this.isArchivedAlertOpen = false;
    },
    async submit () {
      if (this.mergedPatient.archived !== this.value.selectedTargetPatient.archived) {
        this.isArchivedAlertOpen = true;
        await this.patientArchivedStateUpdated();
      }

      await this.sendMergedPatient();
      return true;
    },
    async sendMergedPatient () {
      try {
        await this.mergePatients();
        this.showSuccessNotification();
      } catch (error) {
        this.showErrorNotification();
      }
    },
    async mergePatients () {
      await postToAPI('/api/merge_duplicates/patients', {
        mergedPatient: getApiSerializedPatient(this.mergedPatient),
        selectedTargetPatientId: getUUIDFromIRI(this.value.selectedTargetPatient['@id']),
      });
    },
    showSuccessNotification () {
      const needsVitalCard = this.readVitalCard();
      const message = needsVitalCard
          // eslint-disable-next-line max-len
        ? 'La fusion des 2 dossiers a été réalisée avec succès, mais une lecture de la carte vitale est nécessaire pour mettre à jour les informations liées aux organismes de rattachement.'
        : 'La fusion des 2 dossiers a été réalisée avec succès.';

      const options = needsVitalCard ? { timeout: 5000 } : {};

      NovaTools.notify[needsVitalCard ? 'warning' : 'success'](message, options);
    },
    showErrorNotification () {
      NovaTools.notify.error('Une erreur s\'est produite lors de la fusion des 2 dossiers.');
    },
    readVitalCard () {
      const fields = ['birthRank', 'grandRegime', 'fund', 'center', 'quality'];
      return fields.some((field) => {
        const mergedValue = field === 'grandRegime'
            ? this.value.mergedPatient.billDataset?.grandRegime?.['@id']
            : this.value.mergedPatient.billDataset?.[field];
        const selectedValue = field === 'grandRegime'
            ? this.value.selectedTargetPatient.billDataset?.grandRegime?.['@id']
            : this.value.selectedTargetPatient.billDataset?.[field];
        return mergedValue && selectedValue && mergedValue !== selectedValue;
      });
    },
  },
};
</script>