<template>
  <app-form :submit-method="submit">
    <app-row>
      <app-col>
        <div class="select-duplicate-patient__source-patient">
          <app-avatar
            :size="30"
            :gender="value.patient.gender"
            :label="avatarLabel"
            class="select-duplicate-patient__avatar"
          />
          <span v-test="'souce-patient-identity'" class="select-duplicate-patient__used-identity">{{ value.patient.usedIdentity }}</span>
        </div>
      </app-col>
      <app-col>
        <app-select
          v-model="selectedTargetPatient"
          label="Sélectionnez le dossier en double"
          :items="value.duplicates"
          :item-text="(patient) => `${patient.usedIdentity} (${patient.publicId})`"
          :return-object="true"
          rules="required"
          disabled-required-auto-select
        >
          <template #selection="{item}">
            <app-avatar
              :size="30"
              :color="getAvatarColor(item)"
              :gender="item.gender"
              :label="avatarLabel"
              class="select-duplicate-patient__avatar"
            />
            <span class="select-duplicate-patient__used-identity">{{ item.usedIdentity }}</span>
          </template>
        </app-select>
      </app-col>
    </app-row>
    <section class="select-duplicate-patient__display-duplicates">
      <app-row>
        <app-col>
          <v-card class="mx-2 select-duplicate-patient__card" elevation="6">
            <duplicate-patient-identity :patient="value.patient" />
            <v-container>
              <div class="select-duplicate-patient__card-body">
                <span class="select-duplicate-patient__card-body__info">Prénoms</span>
                <span>{{ value.patient.firstNames }}</span>
              </div>
              <div class="select-duplicate-patient__card-body">
                <span class="select-duplicate-patient__card-body__info">Premier prénom</span>
                <span>{{ value.patient.firstName }}</span>
              </div>
              <div class="select-duplicate-patient__card-body">
                <span class="select-duplicate-patient__card-body__info">Nom de  naissance</span>
                <span>{{ value.patient.birthName }}</span>
              </div>
            </v-container>
          </v-card>
        </app-col>
        <app-col>
          <v-card v-if="selectedTargetPatient" class="mx-2 select-duplicate-patient__card" elevation="6">
            <duplicate-patient-identity :patient="selectedTargetPatient" avatar-color />
            <v-container>
              <div class="select-duplicate-patient__card-body">
                <span class="select-duplicate-patient__card-body__info">Prénoms</span>
                <span>{{ selectedTargetPatient.firstNames }}</span>
              </div>
              <div class="select-duplicate-patient__card-body">
                <span class="select-duplicate-patient__card-body__info">Premier prénom</span>
                <span>{{ selectedTargetPatient.firstName }}</span>
              </div>
              <div class="select-duplicate-patient__card-body">
                <span class="select-duplicate-patient__card-body__info">Nom de  naissance</span>
                <span>{{ selectedTargetPatient.birthName }}</span>
              </div>
            </v-container>
          </v-card>
        </app-col>
      </app-row>
    </section>
  </app-form>
</template>

<script>
import personDetailsMixin from '@/components/ui/personDetails/personDetailsMixin';
import { AVATARS } from '@/constants';

import AppAvatar from '@/components/ui/avatar/AppAvatar.vue';
import AppForm from '@/components/ui/form/AppForm.vue';
import AppSelect from '@/components/ui/form/AppSelect.vue';
import DuplicatePatientIdentity
  from '@/modules/patient/components/sidebar/duplicates/components/DuplicatePatientIdentity.vue';

export default {
  name: 'SelectDuplicatePatientForm',
  components: {
    AppSelect,
    AppAvatar,
    AppForm,
    DuplicatePatientIdentity,
  },
  mixins: [personDetailsMixin],
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data () {
    return { selectedTargetPatient: null };
  },
  watch: {
    'value.duplicates': {
      immediate: true,
      deep: true,
      handler (newValue) {
        if (newValue) {
          this.selectedTargetPatient = this.value.selectedTargetPatient ?? newValue[0];
        }
      },
    },
  },
  methods: {
    submit () {
      return {
        patient: this.selectedTargetPatient,
        resetForms: this.updateResetForm(),
      };
    },
    getAvatarColor (item) {
      if (item.gender === 'male') {
        return AVATARS.other.male.secondaryColor;
      }
      if (item.gender === 'female') {
        return AVATARS.other.female.secondaryColor;
      }

      return AVATARS.other.unknown.secondaryColor;
    },
    updateResetForm () {
      return !! (this.value.lastSelectedTargetPatientId) && (this.value.lastSelectedTargetPatientId !== this.selectedTargetPatient['@id']);
    },
  },
};
</script>

<style scoped lang="scss">
.select-duplicate-patient {
  &__source-patient {
    margin-top: 15px;
  }
  &__used-identity {
    margin-left: 10px;
  }
  &__avatar {
    margin-top: 2px;
  }
  &__display-duplicates {
    margin-top: 30px;
  }
  &__card {
    margin-bottom: 10px;
  }
  &__card-body {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin: 0 0 15px 15px;
    &__info {
      font-style: italic;
      color: var(--v-text-lighten3);
    }
  }
}
</style>