<template>
  <div>
    <aati-modal-form :patient="patient" :is-open.sync="isAatiModalOpen" />
    <aldi-modal-form :patient="patient" :is-open.sync="isAldiModalOpen" />
    <dmti-modal-form :patient="patient" :is-open.sync="isDmtiModalOpen" />
    <imti-modal-form :patient="patient" :is-open.sync="isImtiModalOpen" />
  </div>
</template>

<script>
import { camelCase } from 'lodash';

import { TLSI_TYPES } from '@/modules/patient/constants';
import Patient from '@/modules/patient/models/Patient';

import AatiModalForm from '@/modules/patient/components/sidebar/tlsi/aati/AatiModalForm.vue';
import AldiModalForm from '@/modules/patient/components/sidebar/tlsi/aldi/AldiModalForm.vue';
import DmtiModalForm from '@/modules/patient/components/sidebar/tlsi/dmti/DmtiModalForm.vue';
import ImtiModalForm from '@/modules/patient/components/sidebar/tlsi/imti/ImtiModalForm.vue';

export default {
  name: 'TlsiModals',
  components: {
    AatiModalForm,
    AldiModalForm,
    DmtiModalForm,
    ImtiModalForm,
  },
  props: {
    patient: {
      type: Patient,
      required: true,
    },
    /**
     * Le nom du tlsi à interroger {@link TLSI_TYPES}
     */
    activeTlsi: {
      type: String,
      default: null,
      validator: tlsi => Object.values(TLSI_TYPES).some(({ value }) => value === tlsi),
    },
  },
  data () {
    return { TLSI_TYPES };
  },
  computed: {
    ...Object.values(TLSI_TYPES).reduce((acc, tlsiType) => {
      const getterTitle = camelCase(`is-${tlsiType.value}-ModalOpen`);
      return {
        ...acc,
        [getterTitle]: {
          get () {
            return this.isTlsiModalOpen(tlsiType);
          },
          set (isOpen) {
            this.onTlsiModalOpenUpdate(isOpen);
          },
        },
      };
    }, {}),
  },
  methods: {
    isTlsiModalOpen (tlsiType) {
      return tlsiType.value === this.activeTlsi;
    },
    onTlsiModalOpenUpdate (isOpen) {
      if (! isOpen) {
        this.$emit('update:active-tlsi', null);
      }
    },
  },
};
</script>