<template>
  <div class="duplicate-patient-identity__card-header">
    <app-avatar
      :size="45"
      :color="getAvatarColor(patient)"
      :gender="patient.gender"
      :label="avatarLabel"
    />
    <div class="duplicate-patient-identity__card-title">
      <span v-if="usedIdentityTag && isMobile" class="duplicate-patient-identity__card-identity__info">Identité utilisée</span>
      <div class="duplicate-patient-identity__card-identity">
        <span class="duplicate-patient-identity__card-identity__identity">{{ patient.usedIdentity }}</span>
        <app-icon
          :size="14"
          :color="patient.insIdentity.getColor()"
          icon="ins-status"
          :tooltip="patient.insIdentity.getLabel()"
          class="duplicate-patient-identity__card-identity__ins"
        />
        <span v-if="usedIdentityTag && ! isMobile" class="duplicate-patient-identity__card-identity__info">Identité utilisée</span>
      </div>
      <div>{{ patient.getFormattedBirthDate({ bornOn: true }) }}</div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import personDetailsMixin from '@/components/ui/personDetails/personDetailsMixin';
import { AVATARS } from '@/constants';
import Patient from '@/modules/patient/models/Patient';

import AppAvatar from '@/components/ui/avatar/AppAvatar.vue';

export default {
  name: 'DuplicatePatientIdentity',
  components: { AppAvatar },
  mixins: [personDetailsMixin],
  props: {
    patient: {
      type: Patient,
      required: true,
    },
    avatarColor: {
      type: Boolean,
      default: false,
    },
    usedIdentityTag: {
      type: Boolean,
      default: true,
    },
  },
  computed: { ...mapState('app', ['isMobile']) },
  methods: {
    getAvatarColor (item) {
      if (! this.avatarColor) {
        return null;
      }
      if (item.gender === 'male') {
        return AVATARS.other.male.secondaryColor;
      }
      if (item.gender === 'female') {
        return AVATARS.other.female.secondaryColor;
      }

      return AVATARS.other.unknown.secondaryColor;
    },
  },
};
</script>

<style scoped lang="scss">
.duplicate-patient-identity {
  &__card-header {
    display: flex;
    align-items: center;
    gap: 15px;
    margin: 0 0 30px 15px;
    padding-top: 15px;
  }
  &__card-title {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  &__card-identity {
    display: flex;
    align-items: center;
    gap: 30px;
    &__identity {
      font-weight: 600;
      font-size: 1.4em;
    }
    &__info {
      font-style: italic;
      color: var(--v-text-lighten3);
    }
    &__ins {
      margin-left: -20px;
    }
  }
}
</style>