<template>
  <app-form :key="mergeMedicalPatientFolderFormKey" :submit-method="submit">
    <v-row>
      <v-col offset="3" cols="4">
        <duplicate-patient-identity :patient="value.patient" :used-identity-tag="false" />
      </v-col>
      <v-col cols="4">
        <duplicate-patient-identity
          :patient="value.selectedTargetPatient"
          avatar-color
          :used-identity-tag="false"
        />
      </v-col>
    </v-row>
    <div v-if="loading" class="mb-last-0">
      <div
        v-for="(index) in DEFAULT_CORRESPONDENTS_SKELETONS_AMOUNT"
        :key="index"
        v-test="'merge-medical-folder-skeleton'"
        class="mb-4"
      >
        <app-skeleton-loader type="text" width="40%" />
        <app-skeleton-loader type="text" width="60%" />
      </div>
    </div>
    <div v-else>
      <div v-if="! choiceToMake" v-test="'merge-medical-patient-folder-no-field'" class="merge-medical-folder__info">
        Aucune différence observée dans les données médicales
      </div>
      <div v-else v-test="'merge-medical-patient-folder-fields'" class="merge-medical-folder__info">
        Seuls les champs avec des données différentes sont affichés
      </div>
      <div>
        <template v-for="field in fields">
          <component
            :is="field.component"
            v-if="adaptiveDisplayField(field)"
            :key="field.key"
            v-model="field.model[field.props['field-name']]"
            v-bind="field.props"
          >
            {{ field.label }}
          </component>
        </template>
      </div>
    </div>
  </app-form>
</template>

<script>
import { CIVILITIES } from '@/constants';
import FemaleMedicalDataset
  from '@/modules/patient/components/patientFile/femaleMedicalDataset/models/PatientFemaleMedicalDataset';
import MedicalDataset from '@/modules/patient/models/MedicalDataset';
import NovaTools from '@/nova-tools/NovaTools';
import { getFromAPI } from '@/services/api';

import AppForm from '@/components/ui/form/AppForm.vue';
import AppSkeletonLoader from '@/components/ui/loaders/AppSkeletonLoader.vue';
import DuplicatePatientIdentity
  from '@/modules/patient/components/sidebar/duplicates/components/DuplicatePatientIdentity.vue';
import MergeDuplicatesRadio from '@/modules/patient/components/sidebar/duplicates/components/MergeDuplicatesRadio.vue';

const DEFAULT_CORRESPONDENTS_SKELETONS_AMOUNT = 4;

export default {
  name: 'MergeMedicalPatientFolderForm',
  components: {
    AppSkeletonLoader,
    MergeDuplicatesRadio,
    DuplicatePatientIdentity,
    AppForm,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      DEFAULT_CORRESPONDENTS_SKELETONS_AMOUNT,
      localPatient: null,
      localSelectedTargetPatient: null,
      mergedPatient: null,
      mergeMedicalPatientFolderFormKey: 0,
      loading: true,
      choiceToMake: false,
      displayedFields: [],
    };
  },
  computed: {
    fields () {
      return [
        {
          key: 'bloodType',
          label: 'Groupe sanguin',
          component: 'merge-duplicates-radio',
          model: this.mergedPatient?.medicalDataset,
          props: {
            'patient-field': this.localPatient?.medicalDataset?.bloodType,
            'selected-target-patient-field': this.localSelectedTargetPatient?.medicalDataset?.bloodType,
            'field-name': 'bloodType',
          },
        },
        {
          key: 'breastfeeding',
          label: 'Allaitement',
          component: 'merge-duplicates-radio',
          model: this.mergedPatient?.medicalDataset,
          props: {
            'patient-field': this.getBreastfeeding(this.localPatient?.femaleMedicalDataset),
            'patient-label': this.getBooleanLabel(this.getBreastfeeding(this.localPatient?.femaleMedicalDataset)),
            'selected-target-patient-field': this.getBreastfeeding(this.localSelectedTargetPatient?.femaleMedicalDataset),
            'selected-target-patient-label': this.getBooleanLabel(this.getBreastfeeding(this.localSelectedTargetPatient?.femaleMedicalDataset)),
            'field-name': 'breastfeeding',
          },
        },
        {
          key: 'pregnant',
          label: 'Données liées à la grossesse',
          component: 'merge-duplicates-radio',
          model: this.mergedPatient?.medicalDataset,
          props: {
            'patient-field': 'patient',
            'patient-label': this.getPregnantFullLabel(this.localPatient?.femaleMedicalDataset),
            'selected-target-patient-field': 'selectedPatient',
            'selected-target-patient-label': this.getPregnantFullLabel(this.localSelectedTargetPatient?.femaleMedicalDataset),
            'field-name': 'pregnant',
          },
        },
        {
          key: 'referringPhysician',
          label: 'Médecin traitant',
          component: 'merge-duplicates-radio',
          model: this.mergedPatient?.patient,
          props: {
            'patient-field': this.getReferringPhysicianField(this.localPatient?.referringPhysician),
            'patient-label': this.getReferringPhysicianLabel(this.localPatient?.referringPhysician),
            'selected-target-patient-field': this.getReferringPhysicianField(this.localSelectedTargetPatient?.referringPhysician),
            'selected-target-patient-label': this.getReferringPhysicianLabel(this.localSelectedTargetPatient?.referringPhysician),
            'field-name': 'referringPhysician',
          },
        },
      ];
    },
  },
  watch: {
    'value.resetForms': {
      immediate: true,
      deep: true,
      handler (newValue) {
        if (newValue) {
          this.mergeMedicalPatientFolderFormKey += 1;
        }
      },
    },
    'value.selectedTargetPatient': {
      immediate: true,
      deep: true,
      async handler (newValue) {
        if (newValue && this.value.lastSelectedTargetPatientId && (newValue['@id'] !== this.value.lastSelectedTargetPatientId)) {
          this.loading = true;
          this.localSelectedTargetPatient = await this.addMedicalDataset(this.value.selectedTargetPatient);
          const mergedPatient = await this.addMedicalDataset(this.value.mergedPatient);
          if (mergedPatient) {
            this.mergedPatient = {
              patient: mergedPatient,
              medicalDataset: {
                bloodType: mergedPatient.medicalDataset.bloodType,
                breastfeeding: this.getBreastfeeding(this.localPatient.femaleMedicalDataset),
                pregnant: this.localPatient.femaleMedicalDataset ? 'patient' : null,
              },
            };
          }
          this.loading = false;
        }
      },
    },
    'value.mergedPatient': {
      immediate: true,
      deep: true,
      async handler (newValue) {
        if (newValue && this.mergedPatient) {
          this.mergedPatient = {
            patient: newValue,
            medicalDataset: {
              bloodType: this.mergedPatient.medicalDataset.bloodType,
              breastfeeding: this.mergedPatient.medicalDataset.breastfeeding,
              pregnant: this.mergedPatient.medicalDataset.pregnant,
            },
          };
        }
      },
    },
  },
  async created () {
    this.localPatient = await this.addMedicalDataset(this.value.patient);
    this.localSelectedTargetPatient = await this.addMedicalDataset(this.value.selectedTargetPatient);

    const mergedPatient = await this.addMedicalDataset(this.value.mergedPatient);
    this.mergedPatient = {
      patient: mergedPatient,
      medicalDataset: {
        bloodType: mergedPatient.medicalDataset.bloodType,
        breastfeeding: this.getBreastfeeding(this.localPatient.femaleMedicalDataset),
        pregnant: this.localPatient.femaleMedicalDataset ? 'patient' : null,
      },
    };
    this.loading = false;
  },
  methods: {
    submit () {
      let mergedPatient = this.mergedPatient.patient;
      mergedPatient = this.updateReferringPhysician(mergedPatient);
      mergedPatient = this.updateFemaleMedicalDataset(mergedPatient);
      mergedPatient.medicalDataset.bloodType = this.mergedPatient?.medicalDataset?.bloodType;

      return {
        patient: mergedPatient,
        resetForms: this.value.resetForms,
        lastSelectedTargetPatientId: this.localSelectedTargetPatient['@id'],
        displayedFields: this.displayedFields,
      };
    },
    updateReferringPhysician (mergedPatient) {
      if (! mergedPatient.referringPhysician) {
        return mergedPatient;
      }
      if (mergedPatient.referringPhysician === this.value.patient.referringPhysician?.['@id']) {
        mergedPatient.referringPhysician = this.value.patient.referringPhysician;
      }
      if (mergedPatient.referringPhysician === this.value.selectedTargetPatient.referringPhysician?.['@id']) {
        mergedPatient.referringPhysician = this.value.selectedTargetPatient.referringPhysician;
      }
      return mergedPatient;
    },
    updateFemaleMedicalDataset (mergedPatient) {
      if (! mergedPatient.femaleMedicalDataset) {
        return mergedPatient;
      }
      mergedPatient.femaleMedicalDataset.breastfeeding = this.mergedPatient.medicalDataset.breastfeeding;
      if (this.mergedPatient.medicalDataset.pregnant === 'patient') {
        return this.updatePatientPregnancyData(mergedPatient, this.localPatient.femaleMedicalDataset);
      }
      if (this.mergedPatient.medicalDataset.pregnant === 'selectedPatient') {
        return this.updatePatientPregnancyData(mergedPatient, this.localSelectedTargetPatient.femaleMedicalDataset);
      }

      return mergedPatient;
    },
    updatePatientPregnancyData (mergedPatient, femaleMedicalDataset) {
      mergedPatient.femaleMedicalDataset.pregnant = femaleMedicalDataset.pregnant;
      mergedPatient.femaleMedicalDataset.conceptionDate = femaleMedicalDataset.conceptionDate;
      mergedPatient.femaleMedicalDataset.deliveryDate = femaleMedicalDataset.deliveryDate;
      mergedPatient.femaleMedicalDataset.lastPeriodDate = femaleMedicalDataset.lastPeriodDate;

      return mergedPatient;
    },
    async addMedicalDataset (patient) {
      try {
        if ((typeof patient.medicalDataset === 'string') && patient.femaleMedicalDataset && (typeof patient.femaleMedicalDataset === 'string')) {
          const femaleMedicalDataset = await getFromAPI(patient.femaleMedicalDataset);
          patient.femaleMedicalDataset = new FemaleMedicalDataset(femaleMedicalDataset.data);
        }
        if (typeof patient.medicalDataset === 'string') {
          const medicalDataset = await getFromAPI(patient.medicalDataset);
          patient.medicalDataset = new MedicalDataset(medicalDataset.data);
        }
        if (! patient.medicalDataset || typeof patient.medicalDataset === 'undefined') {
          patient.medicalDataset = new MedicalDataset();
        }
        return patient;
      } catch {
        this.$emit('update:is-open', false);
        NovaTools.notify.error('Une erreur s\'est produite lors de la récupération des doublons.');
        return null;
      }
    },
    adaptiveDisplayField (field) {
      if (field.key === 'breastfeeding') {
        return this.displayFemaleMedicalDatasetField(field.label, field.props['patient-label'], field.props['selected-target-patient-label']);
      }
      const specialFields = ['pregnant'];
      return specialFields.includes(field.key) ?
        this.displayField(field.label, field.props['patient-label'], field.props['selected-target-patient-label']) :
        this.displayField(field.label, field.props['patient-field'], field.props['selected-target-patient-field']);
    },
    displayField (label, sourceField, targetField) {
      if (! sourceField || ! targetField) {
        return false;
      }

      const isDifferent = this.areFieldsDifferent(sourceField, targetField);
      if (! isDifferent) {
        return false;
      }

      this.choiceToMake = true;
      if (! this.displayedFields.includes(label)) {
        this.displayedFields.push(label);
      }

      return true;
    },
    areFieldsDifferent (sourceField, targetField) {
      if (Array.isArray(sourceField) && Array.isArray(targetField)) {
        return (
          sourceField.length !== targetField.length ||
          ! sourceField.every((value, index) => value === targetField[index])
        );
      }
      return sourceField !== targetField;
    },
    displayFemaleMedicalDatasetField (label, sourceField, targetField) {
      if (! this.value.patient.femaleMedicalDataset || ! this.value.selectedTargetPatient.femaleMedicalDataset) {
        return false;
      }

      return this.displayField(label, sourceField, targetField);
    },
    getReferringPhysicianLabel (referringPhysician) {
      if (referringPhysician) {
        const civilitiesShortLabel = CIVILITIES[referringPhysician.civility?.toUpperCase()]?.shortLabel;
        return `${civilitiesShortLabel} ${referringPhysician.firstNames} ${referringPhysician.familyName}`;
      }

      return null;
    },
    getReferringPhysicianField (referringPhysician) {
      return referringPhysician?.['@id'] ?? null;
    },
    getBooleanLabel (value) {
      if (typeof value === 'boolean') {
        return value ? 'oui' : 'non';
      }
      return null;
    },
    getBreastfeeding (femaleMedicalDataset) {
      if (! femaleMedicalDataset || typeof femaleMedicalDataset === 'undefined') {
        return null;
      }
      return femaleMedicalDataset.breastfeeding;
    },
    getPregnantFullLabel (femaleMedicalDataset) {
      if (! femaleMedicalDataset || typeof femaleMedicalDataset === 'undefined') {
        return null;
      }

      return femaleMedicalDataset.getPregnantFullLabel();
    },
  },
};
</script>

<style scoped lang="scss">
.merge-medical-folder {
  &__info {
    font-style: italic;
    color: var(--v-text-lighten3);
    font-weight: bolder;
    font-size: 0.9em;
  }
}
</style>