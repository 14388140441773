import Prescription from '@/modules/patient/models/Prescription';
import NovaTools from '@/nova-tools/NovaTools';
import { getFromAPI, postToAPI, putToAPI, deleteFromAPI } from '@/services/api';
import { getUUIDFromIRI } from '@/utils/functions/getUUIDFromIRI';

export default class PrescriptionTemplate extends Prescription {

  constructor (options = {}) {
    super(options);

    this.createdBy = options.createdBy?.['@id'] || null;
  }

  static async getAllForOrganisation () {
    const { data } = await getFromAPI('/api/get_by_organisation/prescription_templates');
    return data['hydra:member'].map(prescriptionTemplate => new PrescriptionTemplate(prescriptionTemplate));
  }

  static async getAllForPractitioner (practitionerId) {
    const { data } = await getFromAPI(`/api/get_by_user/${getUUIDFromIRI(practitionerId)}/prescription_templates`);
    return data['hydra:member'].map(prescriptionTemplate => new PrescriptionTemplate(prescriptionTemplate));
  }

  /**
   * Insère une ordonnance type vers l'API
   * @param {Object} prescriptionTemplate
   * @param {Boolean} showNotification
   * @returns {PrescriptionTemplate}
   */
  static async insert (prescriptionTemplate, showNotification = true) {
    const { data } = await postToAPI('/api/prescription_templates', prescriptionTemplate);
    if (showNotification) {
      NovaTools.notify.success('L\'ordonnance type a été créée avec succès');
    }
    return new PrescriptionTemplate(data);
  }

  /**
   * Mettre à jour une ordonnance type à partir de l'API
   * @param {Object} prescriptionTemplate
   * @param {Boolean} showNotification
   * @returns {PrescriptionTemplate}
   */
  static async update (prescriptionTemplate, showNotification = true) {
    const { data } = await putToAPI(prescriptionTemplate['@id'], { data: prescriptionTemplate });
    if (showNotification) {
      NovaTools.notify.success('L\'ordonnance type a été remplacée avec succès');
    }
    return new PrescriptionTemplate(data);
  }

  /**
   * Supprime une ordonnance type à partir de l'API
   * @param {Object} prescriptionTemplate
   * @param {Boolean} showNotification
   */
  static async delete (prescriptionTemplate, showNotification = true) {
    await deleteFromAPI(prescriptionTemplate['@id']);
    if (showNotification) {
      NovaTools.notify.success('L\'ordonnance type a été supprimée avec succès');
    }
  }
}