<template>
  <app-container class="home">
    <dashboard-header v-test="'dashboard-header'" />
    <div v-if="showPromotionBanner" class="cp-promotion-banner my-6">
      <v-alert
        border="left"
        shaped
        color="pink darken-1"
        icon="mdi-bell"
        dark
      >
        <p>Nouveauté clé pour easy-care : easy-reco, une IA avancée en exclusivité.<br/>
          Rendez-vous à Paris au Salon CMGF 2025 du 27 au 29 mars, stand n°42, pour découvrir easy-reco en avant-première.</p>
      </v-alert>
    </div>
    <section
      v-if="haveAccessToDashboard"
      class="mt-6"
    >
      <user-dashboard v-test="'user-dashboard'" />
    </section>
    <section v-else>
      <app-row>
        <app-col cols="12">
          <app-panel>Aucun élément à afficher</app-panel>
        </app-col>
      </app-row>
    </section>
  </app-container>
</template>

<script>
import { mapGetters } from 'vuex';

import apiAuth from '@/services/apiAuth';
import { isPartnerAccess } from '@/services/partnerService';

import AppContainer from '@/components/ui/container/AppContainer.vue';
import AppPanel from '@/components/ui/panel/AppPanel.vue';
import DashboardHeader from '@/modules/users/components/dashboard/header/DashboardHeader.vue';
import UserDashboard from '@/views/UserDashboard.vue';

export default {
  name: 'TheHomePage',
  components: {
    AppContainer,
    AppPanel,
    UserDashboard,
    DashboardHeader,
  },
  data () {
    return {
      showPromotionBanner: true,
    };
  },
  computed: {
    ...mapGetters('auth', ['isCurrentUserPractitioner', 'isCurrentUserSecretary']),
    haveAccessToDashboard () {
      return this.isCurrentUserPractitioner || this.isCurrentUserSecretary;
    },
  },
  async created () {
    if (isPartnerAccess()) {
      await this.$router.push('/patients');
    }
    const gcuValidationNeeded = localStorage.getItem('gcuValidationNeeded') ?? false;
    if (gcuValidationNeeded) {
      const authPayload = apiAuth.getAuthPayload();
      localStorage.removeItem('gcuValidationNeeded');
      if (authPayload.gcu?.accepted !== true) {
        await apiAuth.redirectToAuthentication();
      }
    }
  },
};
</script>
