<template>
  <div
    class="app-stepped-form"
    :class="componentClass"
  >
    <div class="app-stepped-form__stepper">
      <app-stepper
        :steps="formattedSteps"
        :value="currentStep"
        data-test="stepper"
      />
    </div>
    <app-divider
      v-if="showDividers"
      data-test="divider"
      class="my-4 mx-n4"
    />
    <v-window
      v-model="currentStep"
      class="app-stepped-form__form"
    >
      <v-window-item
        v-for="(form, formIndex) in forms"
        :key="formIndex"
      >
        <app-form-wrapper
          :ref="`formWrapper-${formIndex}`"
          data-test="form-wrapper"
          :data-index="formIndex"
          :class="{ 'py-4': ! showDividers }"
          :value="form.value"
          :form="form.form"
        >
          <template #default="{ localValue, formValidation, validationObserver, isSubmitting, formId }">
            <component
              :is="form.form"
              :id="formId"
              :ref="`form-${formIndex}`"
              :value="localValue"
              :validation-observer="validationObserver"
              v-bind="form.props"
              @submitForm="(submitMethod) => handleSubmitForm(formIndex, submitMethod)"
            />
            <portal
              v-if="currentStep === formIndex"
              to="form-actions"
              data-test="actions"
            >
              <slot
                name="actions"
                :is-valid="formValidation.valid"
                :is-submitting="isSubmitting"
                :form-id="formId"
              >
                <app-divider
                  v-if="showDividers"
                  data-test="divider"
                  class="my-4 mx-n4"
                />
                <div class="app-stepped-form__actions">
                  <v-spacer />
                  <app-button
                    v-if="currentStep > 0"
                    color="secondary"
                    data-test="previous-step-button"
                    class="mr-2"
                    :disabled="isSubmitting"
                    :label="form.previousText || 'Précédent'"
                    @click="goToPreviousStep"
                  />
                  <app-button
                    :loading="isSubmitting"
                    color="primary"
                    type="submit"
                    :form="formId"
                    data-test="action-button"
                    :disabled="! formValidation.valid"
                    :label="getFormSubmitText(form) "
                  />
                </div>
              </slot>
            </portal>
          </template>
        </app-form-wrapper>
      </v-window-item>
    </v-window>
    <portal-target name="form-actions" />
  </div>
</template>

<script>
import AppFormWrapper from './AppFormWrapper.vue';
import FormMixin from './mixins/FormMixin';

import AppDivider from '@/components/ui/divider/AppDivider.vue';
import AppStepper from '@/components/ui/stepper/AppStepper.vue';

/**
 * Formulaire à étapes de l'application.
 * Chaque étape est présentée une à une.
 * Des boutons permettent de passer d'une étape à une autre
 */
export default {
  name: 'AppSteppedForm',
  components: {
    AppFormWrapper,
    AppStepper,
    AppDivider,
  },
  mixins: [FormMixin],
  inheritAttrs: false,
  props: {
    /**
     * les différents formulaires permettants de générer l'ensemble des étapes
     * Chaque form est passé par un constructeur du composant (fichier .vue, ou créé programatiquement)
     */
    forms: {
      type: Array,
      required: true,
    },
    /**
     * Indique si le formulaire est positionné à l'intérieur d'un panel
     * Utile pour optimiser les marges et l'utilisation de l'espace du panel
     */
    panel: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return { currentStep: 0 };
  },
  computed: {
    isOnLastStep () {
      return (this.forms.length - 1) === this.currentStep;
    },
    formattedSteps () {
      return this.forms.map((form, stepIndex) => ({ text: form.stepName || `Étape ${stepIndex + 1}` }));
    },
    componentClass () {
      return { 'app-stepped-form--panel': this.panel };
    },
    showDividers () {
      return this.panel;
    },
  },
  mounted () {
    if (this.autoFocus) {
      this.formMixin_focusFirstForm();
    }
  },
  methods: {
    goToPreviousStep () {
      if (this.currentStep > 0) {
        this.currentStep -= 1;
      }
    },
    getFormSubmitText (form) {
      if (form.submitText) {
        return form.submitText;
      }
      return this.isOnLastStep ? 'Envoyer' : 'Suivant';
    },
    async handleSubmitForm (formIndex, submitMethod) {
      try {
        const data = await this.submitForm(this.$refs[`formWrapper-${formIndex}`][0], submitMethod);
        if (! this.isOnLastStep) {
          this.currentStep += 1;
        }
        if (this.forms[formIndex].onSuccess) {
          this.forms[formIndex].onSuccess(data);
        }
      } catch (error) {
        throw new Error(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.app-stepped-form {
  display: flex;
  flex-direction: column;

  &--panel {
    height: 100%;
  }

  &--panel &__form {
    flex: 1 0 auto;
  }

  &__actions {
    display: flex;
  }
}
</style>