import Vue from 'vue';

import { PATIENT_ANTECEDENT_MERCURE_STATUS } from '@/constants/index';


export const state = () => ({
  // Pile des objets à jour et en attente d'envoi au composant concerné
  mercurePatientAntecedents: [],
  // Uuid des antécédents pour un patient, sous forme d'un objet, permettant de connaître le statut des éléments à synchroniser
  patientAntecedentLinks: [],
});

export const getters = {
  getMercurePatientAntecedent: (state) => antecedentIri => (
    state.mercurePatientAntecedents[antecedentIri] ? state.mercurePatientAntecedents[antecedentIri] : null
  ),
  getPatientAntecedentLinks: (state) => patientIri => (state.patientAntecedentLinks[patientIri] ? state.patientAntecedentLinks[patientIri] : null),
};

export const mutations = {
  SET_PATIENT_ANTECEDENT_LINKS (state, { patientIri, patientAntecedents }) {
    if (state.patientAntecedentLinks[patientIri] === undefined) {
      Vue.set(state.patientAntecedentLinks, patientIri, []);
    }
    const antecedentIds = {};
    patientAntecedents?.forEach(antecedent => {
      antecedentIds[antecedent['@id']] = PATIENT_ANTECEDENT_MERCURE_STATUS.INIT;
    });
    Vue.set(state.patientAntecedentLinks, patientIri, antecedentIds);
  },
  ADD_MERCURE_PATIENT_ANTECEDENTS (state, { patientIri, mercurePatientAntecedent }) {
    if (state.patientAntecedentLinks[patientIri] === undefined) {
      Vue.set(state.patientAntecedentLinks, patientIri, []);
    }
    Vue.set(state.mercurePatientAntecedents, mercurePatientAntecedent['@id'], mercurePatientAntecedent);
    Vue.set(state.patientAntecedentLinks[patientIri], mercurePatientAntecedent['@id'], PATIENT_ANTECEDENT_MERCURE_STATUS.CREATE);
  },
  UPDATE_MERCURE_PATIENT_ANTECEDENTS (state, { patientIri, mercurePatientAntecedent }) {
    Vue.set(state.mercurePatientAntecedents, mercurePatientAntecedent['@id'], mercurePatientAntecedent);
    Vue.set(state.patientAntecedentLinks[patientIri], mercurePatientAntecedent['@id'], PATIENT_ANTECEDENT_MERCURE_STATUS.UPDATE);
  },
  DELETE_MERCURE_PATIENT_ANTECEDENTS (state, mercurePatientAntecedent) {
    Object.keys(state.patientAntecedentLinks).some(patientIri => {
      const antecedentIds = state.patientAntecedentLinks[patientIri];
      if (Object.prototype.hasOwnProperty.call(antecedentIds, mercurePatientAntecedent['@id'])) {
        antecedentIds[mercurePatientAntecedent['@id']] = PATIENT_ANTECEDENT_MERCURE_STATUS.DELETE;
        Vue.set(state.patientAntecedentLinks, patientIri, antecedentIds);
        return true;
      }
      return false;
    });
  },
  RESET_PATIENT_ANTECEDENT_LINKS (state, { patientIri, patientAntecedentLinkIri, removeLink }) {
    const patientAntecedentLink = state.patientAntecedentLinks[patientIri];
    if (patientAntecedentLink && Object.prototype.hasOwnProperty.call(patientAntecedentLink, patientAntecedentLinkIri)) {
      removeLink ?
        delete patientAntecedentLink[patientAntecedentLinkIri] :
        patientAntecedentLink[patientAntecedentLinkIri] = PATIENT_ANTECEDENT_MERCURE_STATUS.INIT;
      Vue.set(state.patientAntecedentLinks, patientIri, patientAntecedentLink);
    }
  },
};

export const actions = {
  setMercurePatientAntecedents ({ commit, state }, mercurePatientAntecedent) {
    const patientIri = mercurePatientAntecedent.patient;
    const mercurePatientAntecedentIri = mercurePatientAntecedent['@id'];
    const antecedentDoesNotExist = (
      ! state.patientAntecedentLinks[patientIri] ||
      ! Object.prototype.hasOwnProperty.call(state.patientAntecedentLinks[patientIri], mercurePatientAntecedentIri)
    );
    const actionType = antecedentDoesNotExist ? 'ADD_MERCURE_PATIENT_ANTECEDENTS' : 'UPDATE_MERCURE_PATIENT_ANTECEDENTS';

    commit(actionType, {
      patientIri,
      mercurePatientAntecedent,
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};