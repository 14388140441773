<template>
  <v-radio-group
    v-model="mergedPatientField"
    inline
    class="merge-duplicates-fieldset"
    @change="(newFieldValue) => $emit('input', newFieldValue)"
  >
    <v-row dense>
      <v-col cols="3" align-self="center" class="merge-duplicates-fieldset__label">
        <slot />
      </v-col>
      <v-col cols="4" align-self="center">
        <v-radio v-test="'merge-duplicates-radio-patient'" :value="patientField" dense>
          <template #label>
            <div v-if="getPatientLabel instanceof Array" class="merge-duplicates-fieldset__radio-array-label">
              <span v-for="(label, index) in getPatientLabel" :key="index">
                {{ label }}
              </span>
            </div>
            <div v-else>
              {{ getPatientLabel }}
            </div>
          </template>
        </v-radio>
      </v-col>
      <v-col cols="4" align-self="center">
        <v-radio v-test="'merge-duplicates-radio-selected'" :value="selectedTargetPatientField" dense>
          <template #label>
            <div v-if="getSelectedTargetPatientLabel instanceof Array" class="merge-duplicates-fieldset__radio-array-label">
              <span v-for="(label, index) in getSelectedTargetPatientLabel" :key="index">
                {{ label }}
              </span>
            </div>
            <div v-else>
              {{ getSelectedTargetPatientLabel }}
            </div>
          </template>
        </v-radio>
      </v-col>
    </v-row>
  </v-radio-group>
</template>

<script>
export default {
  name: 'MergeDuplicatesRadio',
  props: {
    patientField: {
      type: [String, Boolean],
      default: null,
    },
    selectedTargetPatientField: {
      type: [String, Boolean],
      default: null,
    },
    patientLabel: {
      type: [String, Array],
      default: null,
    },
    selectedTargetPatientLabel: {
      type: [String, Array],
      default: null,
    },
  },
  data () {
    return { mergedPatientField: null };
  },
  computed: {
    getPatientLabel () {
      return this.patientLabel ?? this.patientField ?? 'aucune valeur';
    },
    getSelectedTargetPatientLabel () {
      return this.selectedTargetPatientLabel ?? this.selectedTargetPatientField ?? 'aucune valeur';
    },
  },
  watch: {
    patientField: {
      immediate: true,
      deep: true,
      handler (newFieldValue) {
        if (newFieldValue) {
          this.mergedPatientField = newFieldValue;
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
.merge-duplicates-fieldset {
  font-weight: 600;
  &__label {
    font-size: 1.1em;
  }
  &__radio-array-label {
    display: flex;
    flex-direction: column;
  }
}
</style>